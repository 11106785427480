/**
 * @Author: Ajay
 * @Date:   05-Jan-2018 15:13 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.item.add.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 18-Apr-2018 15:38 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerItemAddController', BuyerItemAddController);

  BuyerItemAddController.$inject = ['$scope', '$state', '$stateParams', 'ImageUploadService', 'BuyerItemService', 'CsvUploadService', 'UomService', 'ItemGroupService', 'BuyerSupplierMappingService', 'BuyerBusinessService', 'BrandManagementService', 'SupplierDataService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'BuyerDataService', 'NotificationService', 'S3_URL_CONSTANTS', 'AdhocItemPriceUpdateService','UtilService'];

  function BuyerItemAddController($scope, $state, $stateParams, ImageUploadService, BuyerItemService, CsvUploadService,  UomService, ItemGroupService, BuyerSupplierMappingService, BuyerBusinessService, BrandManagementService,SupplierDataService, GLOBAL_CONSTANTS, paginationConstants, BuyerDataService, NotificationService, S3_URL_CONSTANTS, AdhocItemPriceUpdateService,UtilService) {
    var vm = this;
    vm.init = init;
    vm.changeItemType = changeItemType;
    vm.loadUoms = loadUoms;
    vm.loadItemGroups = loadItemGroups;
    vm.getAllBuyerMappedAdhocSuppliers = getAllBuyerMappedAdhocSuppliers;
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.save = save;
    vm.setCompanyLogo = setCompanyLogo;
    vm.uploadLogo = uploadLogo;
    vm.validateMOQ = validateMOQ;
    vm.getAllBrandMappedBusinessOutlets = getAllBrandMappedBusinessOutlets;
    vm.tabIndex = $stateParams.tabIndex ? $stateParams.tabIndex : 0;

    vm.itemTypes = GLOBAL_CONSTANTS.ITEMS_TYPES;
    vm.currencies = GLOBAL_CONSTANTS.CURRENCIES;
    vm.defaultCurrency = GLOBAL_CONSTANTS.DEFAULT_CURRENCY;
    vm.getAllBrandsOfBuyerList = getAllBrandsOfBuyerList;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
    vm.itemDTOObj = {};
    vm.itemDTOObj.itemApprovalNeeded = true;
    vm.getCSVBase64 = getCSVBase64;
    vm.itemBulkUploadCSV = itemBulkUploadCSV;

    vm.itemTypes = GLOBAL_CONSTANTS.ITEMS_TYPES;
    vm.BUYER_ITEM_UPLOAD_CSV = S3_URL_CONSTANTS.BUYER_ITEM_UPLOAD_CSV;
    vm.checkWeightRequired = checkWeightRequired;
    vm.isWeightRequired;
    vm.accessForBuyerItemCodeFeatures = BuyerDataService.getBuyerProfile().accessDTO.accessForBuyerItemCodeFeatures;
    vm.accessForItemApproval = BuyerDataService.getBuyerProfile().accessDTO.accessForItemApproval;
    vm.accessForEzyinventory = BuyerDataService.getBuyerProfile().accessDTO.accessForEzyinventory;
    function save() {
      BuyerItemService.createBuyerItem(vm.itemDTOObj).then(function(response) {
        $scope.$emit('ezyprocureApp:buyerItemUpdate', response.data);
        NotificationService.success({
          title: 'Item',
          message: 'alertMessage.CREATED_SUCCESSFULLY'
        });
        $state.go('buyer.adhoc-tools.items', {
          reload: false
        });
      }, function(error) {
        NotificationService.error({
          title: 'Item Creation',
          error: error
        });
      });
    }

    /**IMAGE UPLOAD**/

    function setCompanyLogo(flowFile) {
      ImageUploadService.getImageBase64(flowFile.file, function(base64Img) {
        vm.uploadLogo(base64Img);
      });
    }

     /**
     * [selectAll Selects all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets or brands]
     */
    function selectAll(dropdown) {
      switch (dropdown) {
        case 'BRANDS':
          vm.itemDTOObj.brandIdList = _.map(vm.brands, _.property('id'));
          break;
        case 'OUTLETS':
          vm.itemDTOObj.businessIdList  = _.map(vm.businessOutlets, _.property('id'));
           break;
        case 'businessOutlets':
          vm.csvUploadData.businessIdList  = _.map(vm.businessOutlets, _.property('id'));
           break;
      }
    }

    /**
     * [deselectAll Deselect all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets or brands]
     */
    function deselectAll(dropdown) {
      switch (dropdown) {
        case 'BRANDS':
          vm.itemDTOObj.brandIdList = [];
          vm.itemDTOObj.businessIdList  = [];
          break;
        case 'OUTLETS':
          vm.itemDTOObj.businessIdList  = [];
          break;
        case 'businessOutlets':
          vm.csvUploadData.businessIdList  = [];
            break;
      }
    }

    /**
     * [getAllBrandsOfBuyerList Gets all brands of selected buyers]
     * @param {Array} buyerIdList
     */
    function getAllBrandsOfBuyerList(){
      if(vm.brands)
        return false;
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        query:'',
        buyerId : vm.buyerId
      };
      BrandManagementService.getAllBrandsOfBuyerList(params)
      .then(function(response){
        vm.brands = response;
      },function(error){
        NotificationService.error({
          title: 'Brand Listing',
          error: error
        });
      });
    }

    function uploadLogo(base64Img) {
      vm.uploadingInProgress = true;
      ImageUploadService.uploadImage(base64Img).
      then(function(responseData) {
        vm.itemDTOObj.item.image = responseData.url;
        // $scope.addItemForm.$pristine = false;
        NotificationService.success({
          title: 'Item Image',
          message: 'alertMessage.UPLOADED_SUCCESSFULLY'
        });
        vm.uploadingInProgress = false;
      }, function(error) {
        vm.uploadingInProgress = false;
        NotificationService.error({
          title: 'Item Image Upload',
          error: error
        });
      });
    }

    function loadUoms() {
      UomService.getAllUoms({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.uoms = response.data;
      }, function(error) {
      });
    }

    function loadItemGroups() {
      ItemGroupService.getAllItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
      });
    }

     function getAllBuyerMappedAdhocSuppliers() {
       var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
       AdhocItemPriceUpdateService.getAllBuyerMappedAdhocSuppliers(buyerId,{
         page: 0,
         size: paginationConstants.maxLimit,
         sort: vm.supplierSort
       }).then(function(response){
         vm.suppliers = response.data;
         vm.itemDTOObj.supplierId = undefined;
       },function(error){
       });
     }


    function getAllBuyerBusinessOutlets() {
      BuyerBusinessService.getAllBuyerBusinesses({
        query: '',
        page: 0,
        size: paginationConstants.maxLimit
      }).then(function(response){
        vm.businessOutlets = response.data;
        vm.itemDTOObj.businessIdList = [];
      },function(error){
      });
    }

    function getCSVBase64() {
      vm.errorList = null;
      var csvFile = vm.files[0];
      if (!csvFile) {
        toastr.error('Please upload a .csv file!', 'CSV File Required');
        return false;
      }
      CsvUploadService.getFileBase64(csvFile.lfFile, function(base64CSV) {
        vm.csvUploadData.encodedCsvFile = base64CSV;
        vm.csvUploadData['itemType'] = vm.csvUploadData.item.itemType;
        vm.csvUploadData.supplierId = null;
        vm.itemBulkUploadCSV(vm.csvUploadData);
      });
    }

    function itemBulkUploadCSV(uploadData) {
      BuyerItemService.itemBulkUploadCSV(uploadData)
      .then(function(response) {
        vm.files = [];
        NotificationService.success({
          title: 'Buyer Adhoc Items',
          message: 'alertMessage.UPLOADED_SUCCESSFULLY'
        });
      }, function(error) {
        vm.recordError = error.data;
        NotificationService.error({
          title: 'Buyer Adhoc Item Bulk Upload',
          error: error
        });
      });
    }

    function init() {
      vm.loadUoms();
      vm.loadItemGroups();
      vm.csvUploadData = {};
      vm.csvUploadData.itemApprovalNeeded = true;
    }

    function changeItemType(itemType) {
      vm.csvUploadData.itemType = itemType;
      if (itemType !== 'SUPPLIER') {
        vm.itemDTOObj.supplierId = undefined;
        vm.itemDTOObj.businessIdList = [];
        vm.csvUploadData.supplierId = undefined;
        vm.csvUploadData.businessIdList = [];
      }
    }

    /**
     * getAllBrandMappedsBusinessOutlets gets all BO of selected brand and buyer
     * @param {List} brandIdList
     */
    function getAllBrandMappedBusinessOutlets(brandIdList) {
      if (!brandIdList || brandIdList.length === 0){
        vm.getAllBuyerBusinessOutlets();
        return false;
      }
      var itemTerminationDTO = {
        'brandIdList': brandIdList,
        'buyerIdList': [vm.buyerId]
      }
      var params = {
        page: 0,
        size: paginationConstants.maxLimit
      }
      BuyerBusinessService.getAllBrandMappedsBusinessOutlets(itemTerminationDTO, params).then(function(response) {
        vm.businessOutlets  = response.data;
      }, function(error) {
      });
    }

    /**
     * [validateMOQ Validates the MOQ with Multiplier]
     */
    function validateMOQ() {
      UtilService.validateMOQ($scope.addItemForm, vm.itemDTOObj, vm.isWeightRequired);
    }
  
    function checkWeightRequired(uom){
        vm.isWeightRequired = _.find(vm.uoms, function(uomelement) {
            if(uomelement.id === uom) {
                return uomelement.weightRequired;
            }
        });
        validateMOQ();
      }
  }
})();
