/**
* @Author: Ajay
* @Date:   14-Apr-2017 13:34 +05:30
* @Project: Ezyprocure
* @Filename: supplier.invoice-pending.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-01-22T12:13:11+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierInvoicePendingController', SupplierInvoicePendingController);

  SupplierInvoicePendingController.$inject = ['$scope', '$state', 'toastr', 'pagingParams', 'PurchaseOrderService', 'paginationConstants', 'NotificationService','InvoiceService', 'GLOBAL_CONFIG_CONSTANTS', 'GLOBAL_CONSTANTS'];

  function SupplierInvoicePendingController($scope, $state, toastr, pagingParams, PurchaseOrderService, paginationConstants, NotificationService, InvoiceService, GLOBAL_CONFIG_CONSTANTS, GLOBAL_CONSTANTS) {

    var vm = this;
    vm.loadAll = loadAll;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.filter = {};
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptionsUpTo100;
    vm.generateMassInvoice = generateMassInvoice;
    vm.selectAllPO = selectAllPO;
    vm.errorList = [];
    vm.poStatusClass = GLOBAL_CONFIG_CONSTANTS.PURCHASE_ORDER_STATUS.LABEL_CLASS;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;

    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
    * [getSelectedItems- Returs a list of selected PO's]
    */
    function getSelectedItems(poList){
      vm.status = true;
      var poIds = [];
      var selectedPOItems = _.filter(poList, {
        'selected': true
      });
      poIds = _.map(selectedPOItems,  function(processOrderItem){
        if(processOrderItem.purchaseOrder.purchaseOrderStatus == 'PENDING'){
          vm.status = -1;
       }
        return {
          'deliveryDate': processOrderItem.purchaseOrder.processOrder.deliveryDate,
          'purchaseOrderNumber': processOrderItem.purchaseOrder.purchaseOrderNumber
        };
      });
      return poIds;
    }
    /**
    * [generateMassInvoice- Generates invoicefor list of selected PO's]
    */
    function generateMassInvoice(){
      vm.selectedItems = getSelectedItems(vm.pendingProcessOrders);
      if(vm.status === -1){
        NotificationService.simpleErrorToast({
          title: 'error.validation',
           message: 'alertMessage.PLEASE_SELECT_PROCESSED_PO',
        });
        return false;
      }
      if(vm.selectedItems.length>0){
        InvoiceService.massInvoiceGeneration(vm.selectedItems)
      .then(function(response){
        if(response.data.errors.length > 0) {
          vm.errorList = response.data.errors;
          NotificationService.simpleErrorToast({
            title: 'alertMessage.GENERATE_INVOICE',
            message: 'error.validation',
            params: {}
          });
        }
        else{
          NotificationService.success({
            title: 'alertMessage.GENERATE_INVOICE',
            message: 'alertMessage.COMPLETED_SUCCESSFULLY'
          });
          vm.errorList.length = 0;
        }
        vm.loadAll();
      },function(error){
        NotificationService.error({
          title: 'alertMessage.GENERATE_INVOICE',
          error: error
        });
      });
      }
      else{
        NotificationService.simpleErrorToast({
          title: 'error.validation',
           message: 'global.pleaseSelectedOneFromTheList',
        });
      }
    }

    /**
    * [selectAllPO select all Pending Process Orders]
    */
    function selectAllPO() {
      vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
      _.map(vm.pendingProcessOrders, function(poItem) {
        poItem.selected = vm.selectAll;
      });
    }

    function loadAll() {
      PurchaseOrderService.getAllPurchaseOrders({
        poNumber: '',
        purchaseOrderStatus: '' ,
        invoiceStatus: 'PENDING',
        invoiceNumber: '',
        businessOutletId: '',
        businessOutletName: '',
        buyerIdList:'',
        poFromDate: '',
        poToDate: '',
        deliveryFromDate: '',
        deliveryToDate: '',
        grnFromDate : '',
        grnToDate : '',
        supplierId: '',
        itemGroupId: '',
        debitNoteStatus: '',
        creditNoteStatus: '',
        supplierVisibilityType: '',
        grnNumber: '',
        buyerName: '',
        query: !vm.filter.searchText ? '' : vm.filter.searchText,
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        xeroExported: '',
        module : 0
      }).then(function(response){
        vm.pendingProcessOrders = response.data;
        vm.selectAll = false;
        vm.totalItems = response.headers('X-Total-Count');
      },function(error){
        NotificationService.error({
          title: 'alertMessage.GENERATE_INVOICE',
          error: error
        });
      });
    }
    vm.loadAll();
  }
})();
