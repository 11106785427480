/**
 * @Author: lovinc
 * @Date:   2018-08-29T13:16:50+05:30
 * @Project: Ezyprocure
 * @Filename: buyer.item-business-mapping.controller.js
 * @Last modified by:   lovinc
 * @Last modified time: 2018-08-29T13:19:05+05:30
 */



(function() {
    'use strict';

    angular
    .module('ezyprocureApp')
    .controller('BuyerItemOutletMappingController', BuyerItemOutletMappingController);

    BuyerItemOutletMappingController.$inject = ['$scope', '$state', '$stateParams', '$element', 'NotificationService', 'pagingParams', 'BuyerItemOutletMapService', 'BrandManagementService', 'BuyerSupplierMappingService', 'paginationConstants', 'BuyerDataService', 'BrandSupplierMapService', 'ItemBuyerMappingService','CartService','$mdDialog'];

    function BuyerItemOutletMappingController ($scope, $state, $stateParams, $element, NotificationService, pagingParams, BuyerItemOutletMapService, BrandManagementService, BuyerSupplierMappingService, paginationConstants, BuyerDataService, BrandSupplierMapService, ItemBuyerMappingService,CartService,$mdDialog) {
      var vm = this;
      vm.predicate = pagingParams.predicate;
      vm.reverse = pagingParams.ascending;
      vm.allSelected = false;
      vm.sort = sort;
      vm.loadAll = loadAll;
      vm.deleteItemBusinessMapping = deleteItemBusinessMapping;
      vm.init = init;
      vm.mappedItemSearch = '';
      vm.getsuppliersBusinessOutlets = getsuppliersBusinessOutlets;
      vm.businessSelectionChanged = businessSelectionChanged;
      vm.deleteMappingByIds = deleteMappingByIds;
      vm.showConfirm=showConfirm;
      vm.buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      vm.query = {
        limit: paginationConstants.itemsPerPage,
        page: 1
      };
      vm.limitOptions = paginationConstants.limitOptions;
      vm.getSuppliersMappedWithSelectedBuyer = getSuppliersMappedWithSelectedBuyer;
      vm.getAllBrandsOfBuyerList = getAllBrandsOfBuyerList;
      vm.selectAll = selectAll;
      vm.deselectAll = deselectAll;
      vm.checkAllRows = checkAllRows;
      vm.getSelectedRows = getSelectedRows;
      vm.getSelectedItemsDetailList = getSelectedItemsDetailList;
      vm.unmapMultipleItems = unmapMultipleItems;
      vm.selected = {
        supplierIdList: [],
        brandIdList: []
      };
      vm.closeDialog=closeDialog;
      vm.clearCart=clearCart;
      function sort() {
        var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
        return result;
      }

      /**
       * [selectAll Selects all from the list]
       * @param  {[type]} dropdown [Active dropdown - suppliers or brands]
       */
      function selectAll(dropdown) {
        switch (dropdown) {
          case 'BRANDS':
            vm.selected.brandIdList = _.map(vm.brands, _.property('id'));
            break;
          case 'SUPPLIERS':
            vm.selected.supplierIdList  = _.map(vm.suppliersMappedWithSelectedBuyer, _.property('id'));
            break;
        }
      }

      function checkAllRows() {
        vm.mappedItems.forEach(function (item) {
          item.isSelected = vm.allSelected;
        });
      }

      function getSelectedRows() {
        if (vm.mappedItems && vm.mappedItems.length > 0) {
          var selectedIdList = vm.mappedItems.filter(function(item) {
            return item.isSelected;
          });
        }
        return selectedIdList || [];
      }
  
      function getSelectedItemsDetailList() {
        var selectedItemsDetailList = [];
        var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
        vm.mappedItems.forEach(function (item) {
          if(item.isSelected) {
            var itemDetail = {
              buyerId: buyerId,
              itemBusinessMappingId: item.itemBusinessMappingId,
              itemId: item.id,
              userType: 'BUYER'
            }
            selectedItemsDetailList.push(itemDetail);
          }
        });
        return selectedItemsDetailList;
      }

      function unmapMultipleItems() {
        var itemDetailList = getSelectedItemsDetailList();
      // Extract itemIds from itemDetailList to check in the cart
        var itemIds = _.map(itemDetailList, function(item) {
      return item.itemId;
        });
        var matchingItemsData = CartService.checkMultipleItemsInCart(itemIds);
        if (matchingItemsData.isAvailable) {
          // If items are found in the cart, show an alert or modal (example using alert)
          var itemNames = _.map(matchingItemsData.matchingItems, function(item) {
            return item.itemName;
          }).join(', ');
          matchingItemsData.itemName=itemNames;
          matchingItemsData["customErrorMessage"]="Can't unmap the item as it is already added in the cart.";
          showDialog(matchingItemsData)
          return;
        }
        
        BuyerItemOutletMapService.unmapMultipleItems(itemDetailList).then(function(response){
          NotificationService.success({
            title: 'Item Mapping',
            message: 'alertMessage.DELETED_SUCCESSFULLY'
          });
          vm.loadAll();
        },function(error){
          const customErrorMessage = error.headers && error.headers('X-ezyprocureApp-error-message');
if (customErrorMessage) {
          var item={customErrorMessage:"Can't unmap the item as it is already added in the cart of another user."}
        showDialog(item);
        }else{NotificationService.error({
          title: 'Item Mapping Deletion',
          error: error
        });}
          
        });
      }

      /**
       * [deselectAll Deselect all from the list]
       * @param  {[type]} dropdown [Active dropdown -  suppliers or brands]
       */
      function deselectAll(dropdown) {
        switch (dropdown) {
          case 'BRANDS':
            vm.selected.brandIdList = [];
            break;
          case 'SUPPLIERS':
            vm.selected.supplierIdList = [];
            break;
        }
      }

      // get all suppliers mapped with buyer
      function getSuppliersMappedWithSelectedBuyer() {
        BuyerSupplierMappingService.getAllBuyerMappedSupplierList(vm.buyerId)
        .then(function(response){
          vm.suppliersMappedWithSelectedBuyer = response.data;
        },function(error) {
          vm.suppliersMappedWithSelectedBuyer = null;
        });
      }

      // Load All supplier items which are mapped
      function loadAll (search) {
        vm.businessSelectionChanged();
        vm.businessOutlets = [];
        var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
        if (!search) {
          vm.mappedItemSearch = '';
        }
        BuyerItemOutletMapService.getAllItemBuyerMappings({
          page: vm.query.page - 1,
          size: vm.query.limit,
          sort: sort(),
          buyerId: buyerId,
          supplierIdList:vm.selected.supplierIdList,
          brandIdList: vm.selected.brandIdList,
          query: vm.mappedItemSearch,
        }
        ).then(function(response){
          vm.totalItems = response.headers('X-Total-Count');
          vm.mappedItems = response.data;
        },function(error){
          NotificationService.error({
            title: 'Mapped Items Listing',
            error: error
          });
        });
      }

      /**
       * [getAllBrandsOfBuyerList Gets all brands of selected buyers]
       * @param {Array} buyerIdList
       */
      function getAllBrandsOfBuyerList(){
        if(vm.brands)
          return false;
        var params = {
          page: 0,
          size: paginationConstants.maxLimit,
          query:'',
          buyerId : vm.buyerId
        };
        BrandManagementService.getAllBrandsOfBuyerList(params)
        .then(function(response){
          vm.brands = response;
        },function(error){
          NotificationService.error({
            title: 'Brand Listing',
            error: error
          });
        });
      }

      //delete a mapping
      function deleteItemBusinessMapping(itemBusinessMappingId) {
        BuyerItemOutletMapService.deleteMappingByItemBusinessMappingId(itemBusinessMappingId).then(function(response){
          NotificationService.success({
            title: 'Item Mapping',
            message: 'Deleted successfully'
          });
          vm.businessSelectionChanged();
          vm.loadAll();
        },function(error){
          NotificationService.error({
            title: 'Item Mapping Deletion',
            error: error
          });
        });
      }

      function init() {
        vm.loadAll();
      }

      vm.init();

      //get all business outlets for unmapping
      function getsuppliersBusinessOutlets (itemId) {

        var params = {
          page: 0,
          size: paginationConstants.maxLimit,
          sort: sort(),
          itemId:itemId
        };
        BuyerItemOutletMapService.getBusinessOutlets(params).then(function(response){
          vm.businessOutlets = response.data;
        },function(error){
        });
      }

      function businessSelectionChanged(){
        vm.businessOutlets = [];
      }

      /**
     * [deleteMappingByIds by ids ]
     * @param {*} itemId
     */
    function deleteMappingByIds(itemBusinessMappingId, itemId) {
      var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      var matchingItemData= CartService.checkMultipleItemsInCart([itemId]);
      if(matchingItemData.isAvailable){
        var itemNames = _.map(matchingItemData.matchingItems, function(item) {
          return item.itemName;
        }).join(', ');
        matchingItemData["itemName"]=itemNames;
        matchingItemData["customErrorMessage"]="Can't unmap the item as it is already added in the cart.";
        showDialog(matchingItemData);
        return;
      }
      BuyerItemOutletMapService.deleteMappingByItemBusinessMappingIds(itemBusinessMappingId, buyerId, itemId, 'BUYER').then(function(response){
        NotificationService.success({
          title: 'Item Mapping',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      },function(error){
        const customErrorMessage = error.headers && error.headers('X-ezyprocureApp-error-message');
if (customErrorMessage) {
          var item={customErrorMessage:"Can't unmap the item as it is already added in the cart of another user."}
        showDialog(item);
        }else{
          NotificationService.error({
            title: 'Item Mapping Deletion',
            error: error
          });
        }
    
      });
    }


    function showDialog(item) {
      vm.item=item;
      // Define the dialog template
      $mdDialog.show({
        templateUrl: 'app/core/common-templates/itemDialogTemplate.html',
        controller: function() {
            return vm;
        },
        controllerAs: 'vm',
        escapeToClose: false,
        // targetEvent: event,
        clickOutsideToClose: false
    });
    };

    function closeDialog() {
      $mdDialog.hide(); // This will close the dialog
    }


     function clearCart() {
       $state.go('buyer.cart-checkout', {}, { reload: false});
       $mdDialog.hide();
    };

    function showConfirm(ev, itemBusinessMappingId, itemId) {
      var confirm = $mdDialog.confirm()
        .title('Do you really want to unmap this item?')
        .textContent('This action cannot be reverted.')
        .targetEvent(ev)
        .ok('Unmap')
        .cancel('Cancel');
    
      $mdDialog.show(confirm).then(function() {
        if (!itemBusinessMappingId || !itemId) {
          unmapMultipleItems(); // Call this if either argument is null or undefined
        } else {
          deleteMappingByIds(itemBusinessMappingId, itemId); // Otherwise, delete by ID
        }
      }, function() {
        // Cancel action (No need to do anything)
      });
    }
 // All methods should be defined above this point

    }
  })();
