/**
* @Author: Elsa
* @Date:   08-Mar-2017 10:38 +05:30
* @Filename: buyer.receiving-orders.details.controller.js
 * @Last modified by:   Abhay
 * @Last modified time: 2020-02-21T11:44:31.933Z+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerReceivingOrderDetailsController', BuyerReceivingOrderDetailsController);

  BuyerReceivingOrderDetailsController.$inject = ['$scope', '$document', '$rootScope', '$filter', '$state', '$stateParams', 'toastr', '$mdDialog', 'BuyerReceivingOrderService', 'ImageUploadService', 'BuyerOCRService', 'BuyerDataService', 'OCRImageUploadService', 'Auth', 'UTCDateService', 'NotificationService', 'GLOBAL_CONSTANTS','$timeout','PromotionService','$q','BuyerItemService','GlMappingService','UtilService'];

  function BuyerReceivingOrderDetailsController($scope, $document, $rootScope, $filter, $state, $stateParams, toastr, $mdDialog, BuyerReceivingOrderService, ImageUploadService, BuyerOCRService, BuyerDataService, OCRImageUploadService, Auth, UTCDateService, NotificationService, GLOBAL_CONSTANTS,$timeout,PromotionService, $q, BuyerItemService, GlMappingService, UtilService) {
    var vm = this;
    vm.init = init;
    vm.loadAll = loadAll;
    vm.poNumber = $stateParams.poNumber;
    vm.deliveryDate = new Date($stateParams.deliveryDate);
    vm.accessforDeliveryDateEdit = Auth.hasPermission('accessForAmendGrnDeliveryDate');
    vm.accessForGrnPriceUpdate = Auth.hasPermission('accessForGrnPriceUpdate');
    vm.showSignaturePad = showSignaturePad;
    vm.showBatchDetailsModal = showBatchDetailsModal;
    vm.validateWarehouseBin = validateWarehouseBin;
    vm.processGoodReceive = processGoodReceive;
    vm.restructureItemDetailsList = restructureItemDetailsList;
    vm.validateBatchList = validateBatchList;
    vm.removeBatchDetail = removeBatchDetail;
    vm.reassignItemProperties = reassignItemProperties;
    vm.getBuyerResponses = getBuyerResponses;
    vm.getSignature = getSignature;
    vm.cancel = cancel;
    vm.uploadSignature = uploadSignature;
    vm.signatureUrl = '';
    vm.testImage = testImage;
    vm.skipInvoiceUpload = skipInvoiceUpload;
    vm.interceptToOCRModule = interceptToOCRModule;
    vm.getGRNfromResponses = getGRNfromResponses;
    vm.checkAlreadyProcessed = checkAlreadyProcessed;
    var tempItemDetails = {};
    vm.amountCalculation = amountCalculation;
    vm.setRejectedQuantity = setRejectedQuantity;
    vm.initializeBin = initializeBin;
    vm.changeWeight = changeWeight;
    vm.calculateSubTotal = calculateSubTotal;
    vm.calculateItemPrice = calculateItemPrice;
    vm.confirmSkipInvoiceUploadModal = confirmSkipInvoiceUploadModal;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.calculateDiscount = calculateDiscount;
    vm.accessForNegativeBuyingBuyerView = Auth.hasPermission('accessForNegativeBuyingBuyerView');
    vm.tempPercentDiscount = 0; //Setting initial value for discount variable in percentage
    vm.tempAbsoluteDiscount = 0; //Setting initial value for discount variable in absolute
    vm.grandTotalAfterDiscount = grandTotalAfterDiscount;
    vm.isWeightChanged = isWeightChanged;
    //Batch details properties and functions
    vm.updateItemBatchList = updateItemBatchList;
    vm.deleteBatchDetail = deleteBatchDetail;
    vm.updateTotalQuantity = updateTotalQuantity;
    vm.checkDuplicateBatch = checkDuplicateBatch;
    vm.showErrorMessage = showErrorMessage;
    vm.selectedIndex = undefined;
    vm.errorMessage = undefined;
    vm.closeDialog = closeDialog;
    vm.submitBatchDetails = submitBatchDetails;
    vm.batchNo = undefined;
    vm.batchQty = undefined;
    vm.totalItemQuantity = 0;
    vm.batchProductionDate = new Date();
    vm.batchExpiryDate = new Date();
    vm.itemBatchList = [];
    vm.getInventoryWarehouse = getInventoryWarehouse;
    vm.getInventoryBins = getInventoryBins;
    vm.businessOutlets = [];
    vm.checkWarehouseSelection = checkWarehouseSelection;
    vm.accessForEzyinventory = BuyerDataService.getBuyerProfile().accessDTO.accessForEzyinventory;
    vm.accessForGRAddBuffer = BuyerDataService.getBuyerProfile().accessDTO.accessForGRAddBuffer;
    vm.accessForGRDeliveryDate = BuyerDataService.getBuyerProfile().accessDTO.accessForAmendGrnDeliveryDate;
    vm.todaysDate = new Date();
    vm.promptRejectRemark = promptRejectRemark;
    vm.proceedToRejectOrder = proceedToRejectOrder;
    vm.rejectRemarks = '';
    vm.isquantityChanged = isquantityChanged;
    vm.changeQuantity = changeQuantity;
    vm.selectedItemPriceCalculation = selectedItemPriceCalculation;
    vm.accessForMandatoryGRN = Auth.hasPermission('accessForMandatoryGRN');
    vm.adhocOffBoardSupplier = "ADHOC_OFF_BOARD_SUPPLIER";
    vm.calculateGst = calculateGst;
    vm.getBuyerPromotions=getBuyerPromotions;
    vm.grHardCopyDetails = {};
    vm.grnHardcopyDetails = {};
    // item details header warehouse and bin
    vm.headerWarehouseAndBin = {
      warehouseList: [],
      binList: []
    };
    vm.changeChildWarehouse = changeChildWarehouse;
    vm.accessForGRNReceiveDateEdit = BuyerDataService.getBuyerProfile().accessDTO.accessForGRNReceiveDateEdit;
    vm.accessForMandatoryGrnData = BuyerDataService.getBuyerProfile().accessDTO.accessForMandatoryGrnData;
    // negative buying
    vm.isNegativeBuying = isNegativeBuying;
    vm.selectBarcodeChange = selectBarcodeChange;
    vm.otherChargesAmount = vm.otherChargesPerCent = 0;
    var timeoutCheck;
    vm.calculateOtherCharges = calculateOtherCharges;
    vm.HAOMART_BUYER_ID = GLOBAL_CONSTANTS.HAOMART_BUYER_ID;
    vm.buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
    vm.accessForAdhocDiscount = Auth.hasPermission('accessForAdhocDiscount');
    vm.calculateGST = calculateGST;
    vm.isHaomartAccount = isHaomartAccount;
    vm.changeViewType = changeViewType;
    vm.viewType = 'List';
    vm.othersRejectRemark = othersRejectRemark;
    vm.othersTextShow = false;
    $scope.newId = 0;
    vm.othersRejectRemarkAll = othersRejectRemarkAll;
    vm.allRejectRemarkText = '';
    vm.promotions=[];
    var selectedItem;
    var index;
    var freeItemAppliedToIndex;
    vm.isPriceChanged = isPriceChanged;
    vm.addNewGRN = addNewGRN;
    vm.newGRNItemDTO = {
      items: []
    };
    vm.itemCodeChanged = itemCodeChanged;
    vm.newSelectedItems = [];
    vm.deleteItemByIndex = deleteItemByIndex;
    vm.isDuplicate = false;
    vm.searchForItems = searchForItems;
    vm.itemSearchQuery = {};
    vm.checkGlCode = checkGlCode;
    vm.accessForGlModule = Auth.hasPermission('accessForGlModule');
    vm.errorGlcode=[];
    vm.accessForOcrPoQtyPrice = Auth.hasPermission("accessForOcrPoQtyPrice");
    vm.computeItemCost = computeItemCost;
    vm.saveGRNNewItems = saveGRNNewItems;
    vm.enableUpdateButton = false;
    vm.accessForGrnAddItem = Auth.hasPermission("accessForGrnAddItem");
   function changeViewType(type) {
      vm.viewType = type;
    }
    function othersRejectRemarkAll(e){
      if (e == "Others") {
          vm.othersTextShow = true
        }else{
          vm.othersTextShow = false
        }
    }
    function othersRejectRemark(e, data, id){
        e == "Others" ? vm.othersTextShow = true : vm.othersTextShow = false;
        if(vm.promotions.length>0 && vm.itemDetails.items[freeItemAppliedToIndex].buyerResponse.rejectedQuantity>0)
        {
          vm.itemDetails.items[freeItemAppliedToIndex].buyerResponse.buyerRemark=e;
        }
    }
    //select barcode text
    vm.selectBarcode = function(){
      if($scope.barcodeform.$dirty && $scope.searchTerm ){
        $document[0].getElementById('barcodeSearch').select();
      }
    }

    function isHaomartAccount() {
     return _.includes(vm.HAOMART_BUYER_ID, vm.buyerId) ? vm.otherChargesLabel = 'Rebate Return/Discount :' : vm.otherChargesLabel = 'Other Charges :';
    }

    function calculateGST(){
      if(vm.itemDetails.buyerSupplierMapping){
          return (vm.itemDetails.subTotal + vm.itemDetails.deliveryCharges)*vm.itemDetails.gst/100;
      }else if(vm.itemDetails.supplier.visibilityType==='ADHOC_OFF_BOARD_SUPPLIER'){
          var gstValue = vm.itemDetails.gst / 100;
          var gstAmount = vm.itemDetails.grandTotal - (vm.itemDetails.grandTotal / (1 + gstValue))
          return gstAmount;
      }
    }

      //select barcode text change
      function selectBarcodeChange (searchTerm) {
          if (!timeoutCheck) {
              timeoutCheck = selectText();
          }
          else {
              $timeout.cancel(timeoutCheck)
              timeoutCheck = selectText();
          }
      }

      function selectText() {
          var selectTextValue = $timeout(function () {
              $document[0].getElementById('barcodeSearch').select();
              timeoutCheck = undefined;
          }, 1500)
          return selectTextValue;
      }

    function isNegativeBuying(quantity) {
      return vm.accessForNegativeBuyingBuyerView && (Math.sign(quantity) == -1 || Math.sign(quantity) == 0);
    }

    function changeChildWarehouse() {
      if (vm.headerWarehouseAndBin.selectedWarehouseId && vm.itemDetails.ezyInventoryDTO) {
        var selectedWarehouse = vm.itemDetails.ezyInventoryDTO.location.warehouse.find(function (value) {
          return value.id == vm.headerWarehouseAndBin.selectedWarehouseId;
        });
        vm.headerWarehouseAndBin.selectedBin = undefined;
        vm.headerWarehouseAndBin.binList = [];
        if (selectedWarehouse.hasBin && selectedWarehouse.bin.length > 0) {
          vm.headerWarehouseAndBin.binList = selectedWarehouse.bin;
          var defaultBin = selectedWarehouse.bin.find(function (bin) {
            return bin.defaultBin;
          });
          if (!!defaultBin) {
            vm.headerWarehouseAndBin.selectedBin = defaultBin.id;
          }
        }
        else {
          vm.headerWarehouseAndBin.binList = [];
        }
        vm.itemDetails.items.forEach(function (item) {
          item.selectedWarehouseId = vm.headerWarehouseAndBin.selectedWarehouseId;
          item.selectedBin = vm.headerWarehouseAndBin.selectedBin;
          item.binList = vm.headerWarehouseAndBin.binList;
          item.rejectRemarksReason = 0;

        });
      }
    }

    function proceedToRejectOrder(e) {
      $mdDialog.hide(vm.rejectRemarks);

      vm.itemDetails.items.forEach(function (item) {
        item.buyerResponse.buyerRemarkOther = vm.allRejectRemarkText;
        // item.buyerResponse.buyerRemark == 'others' ? item.buyerResponse.buyerRemarkOther : item.itemDetails.buyerResponse.buyerRemark
      });
    }

    function promptRejectRemark(event) {
      $mdDialog.show({
        controller: function () {
          return vm;
        },
        templateUrl: 'app/buyer-modules/receiving-orders/details/template/buyer.receiving-orders.reject-dialog.tmpl.html',
        parent: angular.element(document.body),
        targetEvent: event,
        clickOutsideToClose: true,
        fullscreen: true, // Only for -xs, -sm breakpoints.
        controllerAs: 'vm'
      }).then(function (remarks) {
        updateItemsWithRejectRemarks(remarks);
      }, function () {

      });
    }

    function updateItemsWithRejectRemarks(remarks) {

      vm.itemDetails.items.forEach(function (item, index) {
        item.buyerResponse.buyerAction = 'REJECT';
        item.buyerResponse.buyerRemark = remarks;
        item.buyerResponse.rejectedQuantity = item.uom.weightRequired ? item.weight : item.quantity;
        amountCalculation(index);

      });
    }

    /**
    * [setRejectedQuantity set Rejected Quantity when buyerAction is set to ACCEPT]
    * @param {[type]} index [index]
    */
    function setRejectedQuantity(index){
      vm.itemDetails.items[index].buyerResponse.buyerRemark = "";
      if(vm.itemDetails.items[index].buyerResponse.buyerAction === 'ACCEPT'){
        vm.itemDetails.items[index].isFullReject = false;
        vm.itemDetails.items[index].buyerResponse.rejectedQuantity = 0;
        vm.amountCalculation(index);
      }
      if(vm.itemDetails.items[index].buyerResponse.buyerAction === 'REJECT'){
        vm.itemDetails.items[index].buyerResponse.rejectedQuantity = '';
        vm.disableSubmit = true;
      }
    }

    /**
     * @description submit batch details
     */
    function submitBatchDetails() {
      var isWeightRequired = vm.itemDetails.items[vm.selectedIndex].uom.weightRequired;
      var buyerResponseQuantity = returnBuyerResponse(vm.selectedIndex);
      if ((!isWeightRequired && (vm.totalItemQuantity != (buyerResponseQuantity == null ? vm.itemDetails.items[vm.selectedIndex].quantity : buyerResponseQuantity))) || (isWeightRequired && (vm.totalItemQuantity != (buyerResponseQuantity == null ? vm.itemDetails.items[vm.selectedIndex].weight : buyerResponseQuantity)))) {
        vm.showErrorMessage("GRN quantity and batch quantity mismatch");
        return;
      }
      vm.closeDialog();
    }

    /**
     * @description UoM available in EzyInventory validation
     */
    function checkUoMAvailable() {
      var proceedToProcessGRN = true;
      vm.itemDetails.items.forEach(function (item) {
        if ((!item.isFullReject && !item.uomPresentInEzyInventory && item.isInventory) && proceedToProcessGRN)
          proceedToProcessGRN = item.uomPresentInEzyInventory;
      });
      return proceedToProcessGRN;
    }

    /**
     * @description GRN quantity against batch quantity validation
     */
    function validateBatchAndGRNquantity() {
      var proceedToProcessGRN = true;
      vm.itemDetails.items.forEach(function (item, index) {
        if (item.managementMethod != 'No' && !item.isFullReject && item.isInventory) {
          var totalBatchQuantity = 0;
          var buyerResponseQuantity = returnBuyerResponse(index);
          item.itemBatchDetails.forEach(function (batch) {
            totalBatchQuantity += batch.batchQty;
          });
          var isWeightRequired = item.uom.weightRequired;
          if ((!isWeightRequired && (totalBatchQuantity != (buyerResponseQuantity == null ? item.quantity : buyerResponseQuantity))) || (isWeightRequired && (totalBatchQuantity != (buyerResponseQuantity == null ? item.weight : buyerResponseQuantity)))) {
            proceedToProcessGRN = false
          }
        }
      });
      return proceedToProcessGRN;
    }

    /**
     * @description initialize bin on warehouse selection
     */
    function initializeBin(index) {
      var warehouseById = vm.itemDetails.ezyInventoryDTO.location.warehouse.find(function (value) {
        return value.id == vm.itemDetails.items[index].selectedWarehouseId;
      });
      vm.itemDetails.items[index].selectedBin = null;
      if (warehouseById.hasBin && warehouseById.bin.length > 0) {
        vm.itemDetails.items[index].binList = warehouseById.bin;
        var defaultBin = warehouseById.bin.find(function (bin) {
          return bin.defaultBin;
        });
        if(!!defaultBin) {
          vm.itemDetails.items[index].selectedBin = defaultBin.id;
        }
      }
      else {
        vm.itemDetails.items[index].binList = [];
      }

    }

    /**
     * @description return buyer response
     */
    function returnBuyerResponse(index) {
      var item = vm.itemDetails.items[index];
      if(item.buyerResponse && item.buyerResponse.rejectedQuantity) {
        if(item.uom.weightRequired) {
          return item.buyerResponse.weight;
        }
        else {
          return item.buyerResponse.quantity;
        }
      }
      else {
        return null;
      }
    }

    /**
     * @description add batch details to list
     */
    function updateItemBatchList() {

      if (vm.checkDuplicateBatch()) {
        vm.showErrorMessage($filter('translate')('alertMessage.BATCH_ALREADY_EXISTS'));
        return;
      }
      var isWeightRequired = vm.itemDetails.items[vm.selectedIndex].uom.weightRequired;
      var buyerResponseQuantity = returnBuyerResponse(vm.selectedIndex);

      if (!isWeightRequired) {
        if ((vm.batchQty + vm.totalItemQuantity) > (buyerResponseQuantity == null ? vm.itemDetails.items[vm.selectedIndex].quantity : buyerResponseQuantity)) {
          vm.showErrorMessage($filter('translate')('alertMessage.BATCH_QUANTITY_EXCEEDS_PO'));
          return;
        }
      }
      else {
        /**
         * Commenting PO Quantity 20% validation
         */

        // var weightTwentyPercent = buyerResponseQuantity == null ? (vm.itemDetails.items[vm.selectedIndex].weight * 20) / 100 : (buyerResponseQuantity * 20) / 100;
        // if ((vm.batchQty + vm.totalItemQuantity) > (buyerResponseQuantity == null ? (vm.itemDetails.items[vm.selectedIndex].weight + weightTwentyPercent) : (buyerResponseQuantity + weightTwentyPercent))) {
        //   vm.showErrorMessage($filter('translate')('alertMessage.BATCH_QUANTITY_EXCEEDS_WEIGHT'));
        //   return;
        // }


        if ((vm.batchQty + vm.totalItemQuantity) > (buyerResponseQuantity == null ? (vm.itemDetails.items[vm.selectedIndex].weight) : (buyerResponseQuantity))) {
          vm.showErrorMessage($filter('translate')('alertMessage.BATCH_QUANTITY_EXCEEDS_WEIGHT'));
          return;
        }
      }

      if (!vm.itemDetails.items[vm.selectedIndex].itemBatchDetails) {
        vm.itemDetails.items[vm.selectedIndex].itemBatchDetails = [];
      }

      var batchDetails = { batchNo: vm.batchNo, batchQty: vm.batchQty, productionDate: vm.batchProductionDate, expiryDate: vm.batchExpiryDate };
      vm.itemDetails.items[vm.selectedIndex].itemBatchDetails.push(batchDetails);

      vm.updateTotalQuantity(vm.batchQty, "ADD");
      vm.batchNo = vm.batchQty = undefined;
    }

    /**
     * @description delete item
     */
    function deleteBatchDetail(index) {
      vm.updateTotalQuantity(vm.itemDetails.items[vm.selectedIndex].itemBatchDetails[index].batchQty, "DELETE");
      vm.itemDetails.items[vm.selectedIndex].itemBatchDetails.splice(index, 1);
    }

    /**
     * @description update total quantity
     */
    function updateTotalQuantity(amount, action) {
      if (action == "ADD") {
        vm.totalItemQuantity += amount;
      }
      else {
        vm.totalItemQuantity -= amount;
      }
    }

    /**
     * @description check duplicate batch number
     */
    function checkDuplicateBatch() {
      var hasDuplicate = false;
      angular.forEach(vm.itemDetails.items[vm.selectedIndex].itemBatchDetails, function (value, key) {
        if (value.batchNo == vm.batchNo) {
          hasDuplicate = true;
        }
      });

      return hasDuplicate;
    }

    /**
     * @description close batch detail modal
     */
    function closeDialog() {
      vm.batchQty = vm.batchNo = vm.selectedIndex = undefined;
      $mdDialog.hide();
    }

    /**
     * @description show and hide error messages
     */
    function showErrorMessage(message) {
      vm.errorMessage = message;
      setTimeout(function () { vm.errorMessage = undefined }, 2000);
    }

    /**
     * @description validate warehouse and bin
     */
    function validateWarehouseBin(index, type) {
      var isWarehouseBinValid = true;
      //validation by index
      if (type == "INDEX") {
        var item = vm.itemDetails.items[index];
        if (item.selectedWarehouseId && vm.itemDetails.ezyInventoryDTO) {
          var selectedWarehouse = vm.itemDetails.ezyInventoryDTO.location.warehouse.find(function (warehouse) {
            return warehouse.id == item.selectedWarehouseId;
          })
          if (selectedWarehouse.hasBin) {
            if (!item.selectedBin) {
              isWarehouseBinValid = false;
              //notification bin
              NotificationService.simpleErrorToast({
                title: 'error.validation',
                message: 'alertMessage.BIN_VALIDATION'
              });
            }
          }
        }
        else {
          isWarehouseBinValid = false;
          //notification warehouse
          NotificationService.simpleErrorToast({
            title: 'error.validation',
            message: 'alertMessage.WAREHOUSE_VALIDATION'
          });
        }
        return isWarehouseBinValid;
      }
      //validation by list
      else {
        vm.itemDetails.items.forEach(function (item) {
          //check if item is fully rejected
          if(!item.isFullReject) {
            if (item.selectedWarehouseId && vm.itemDetails.ezyInventoryDTO) {
              var selectedWarehouse = vm.itemDetails.ezyInventoryDTO.location.warehouse.find(function (warehouse) {
                return warehouse.id == item.selectedWarehouseId;
              })
              if (selectedWarehouse.hasBin) {
                if (!item.selectedBin) {
                  isWarehouseBinValid = false;
                }
              }
            }
            else {
              isWarehouseBinValid = false;
            }
          }
        });
        return isWarehouseBinValid;
      }
    }

    /**
     * @description trigger a modal to add batch details
     */
    function showBatchDetailsModal(event, index) {
      vm.selectedIndex = index;
      if (!vm.validateWarehouseBin(index, "INDEX")) {
        //do not proceed
        return;
      }
      vm.batchNo = undefined;
      vm.batchQty = undefined;
      vm.totalItemQuantity = 0;
      vm.batchProductionDate = new Date();
      vm.batchExpiryDate = new Date();

      if(vm.itemDetails.items[vm.selectedIndex].itemBatchDetails && vm.itemDetails.items[vm.selectedIndex].itemBatchDetails.length > 0) {
        vm.itemDetails.items[vm.selectedIndex].itemBatchDetails.forEach(function(batch){
          vm.totalItemQuantity += batch.batchQty;
        });
      }

      $mdDialog.show({
        templateUrl: 'app/buyer-modules/receiving-orders/details/buyer.receiving-orders.details.batch-details.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    /**
    * [changeWeight will be called when weight changed]
    * @param  {[type]} index [description]
    */
    function changeWeight(index){
      tempItemDetails.items[index].weight= vm.itemDetails.items[index].weight;
      vm.amountCalculation(index);
    }

    function changeQuantity(index){
        tempItemDetails.items[index].quantity= vm.itemDetails.items[index].quantity;
        vm.amountCalculation(index);
    }

    /**
    * [calculateSubTotal calculateSubTotal]
    * @param  {[type]} items [updated items]
    */
    function calculateSubTotal(items) {
      var subTotal = 0;
      for(var i = 0; i< items.length; i++){
        subTotal = subTotal + _.round(items[i].totalPrice,2);
      }
      subTotal = _.round(subTotal,2);
      vm.tempSubTotal  = subTotal;
      subTotal = subTotal + vm.otherChargesAmount;
      subTotal = subTotal - vm.itemDetails.discount;

      return subTotal;
    }

    /**
    * [calculateItemPrice to calculate total price of individual item according to whether moq is present or not]
    * @param  {[type]} qty  [item quantity or weight]
    * @param  {[type]} item [item]
    * @return {[type]}      [item totalPrice]
    */

    function calculateItemPrice(qty, item){
      var total;
      var totalPrice=0;
      if( item.uom.moqPrice === true){
            totalPrice = (qty/item.moq) * item.price;
        }
        else{
            total = qty * item.price;
            totalPrice = $filter('totalRoundOfDecimal')(total);
        }
        return _.round(totalPrice,2);
    }

    /**
    * [amountCalculation autocalculation of total amount according to rejectedQuantity and weight]
    * @param  {[type]} index [item index]
    */
    function amountCalculation(index){
      if (vm.itemDetails.items[index].buyerResponse.buyerAction == 'REJECT') {
        vm.disableSubmit = false;
        for (var i = 0; i < vm.itemDetails.items.length; i++) {
          if (vm.itemDetails.items[i].buyerResponse.rejectedQuantity == 0) {
            vm.disableSubmit = true;
          }
        }
      }
      if( vm.itemDetails.items[index].uom.weightRequired === false){
        vm.itemDetails.items[index].buyerResponse.quantity =  tempItemDetails.items[index].quantity - (vm.itemDetails.items[index].buyerResponse.rejectedQuantity ? vm.itemDetails.items[index].buyerResponse.rejectedQuantity : 0);
        if(vm.itemDetails.items[index].buyerResponse && vm.itemDetails.items[index].buyerResponse.quantity == 0) {
          vm.itemDetails.items[index].isFullReject = true;
        }
        else {
          vm.itemDetails.items[index].isFullReject = false;
        }
        tempItemDetails.items[index].totalPrice = +(vm.calculateItemPrice(vm.itemDetails.items[index].buyerResponse.quantity, vm.itemDetails.items[index])).toFixed(2);
        vm.itemDetails.items[index].totalPrice = tempItemDetails.items[index].totalPrice ;
      }
      else if( vm.itemDetails.items[index].uom.weightRequired === true){
        vm.itemDetails.items[index].buyerResponse.weight=  tempItemDetails.items[index].weight-(vm.itemDetails.items[index].buyerResponse.rejectedQuantity ? vm.itemDetails.items[index].buyerResponse.rejectedQuantity : 0);
        if(vm.itemDetails.items[index].buyerResponse && vm.itemDetails.items[index].buyerResponse.weight == 0) {
          vm.itemDetails.items[index].isFullReject = true;
        }
        else {
          vm.itemDetails.items[index].isFullReject = false;
        }
        tempItemDetails.items[index].totalPrice = +(vm.calculateItemPrice(vm.itemDetails.items[index].buyerResponse.weight, vm.itemDetails.items[index])).toFixed(2);
        vm.itemDetails.items[index].totalPrice = tempItemDetails.items[index].totalPrice ;
      }
      vm.calculateDiscount('ABSOLUTE');
      if(vm.itemDetails.items[index].buyerResponse.buyerAction === 'REJECT'){
        var selectedItem= vm.itemDetails.items[index]
        isFreeItemFound(selectedItem,index);
      }

    }


    function selectedItemPriceCalculation(index){
      if(!vm.itemDetails.items[index].selected){
        if(vm.itemDetails.items[index].uom.moqPrice === true){
          vm.itemDetails.items[index].totalPrice = (vm.itemDetails.items[index].quantity / vm.itemDetails.items[index].moq) * vm.itemDetails.items[index].price;
        } else {
          vm.itemDetails.items[index].totalPrice =  vm.itemDetails.items[index].quantity *  vm.itemDetails.items[index].price;

        }
        vm.tempSubTotal = vm.itemDetails.acceptTotalAmount +  vm.itemDetails.items[index].totalPrice;
        tempItemDetails = vm.itemDetails;
      }
      else {
        vm.tempSubTotal = vm.itemDetails.acceptTotalAmount -  vm.itemDetails.items[index].totalPrice;
        vm.itemDetails.items[index].totalPrice = 0;
        tempItemDetails = vm.itemDetails;
      }
      vm.calculateDiscount('ABSOLUTE');
    }

    /**
    * [getSignature description]
    * @return {[type]} [description]
    */
    function getSignature() {
      vm.signature = vm.acceptSignature();
      if(!vm.signature.dataUrl || vm.signature.isEmpty){
        toastr.error('Clear And Try Again', 'Unable To Capture The Signature!');
      }
      else{
        uploadSignature(vm.signature.dataUrl);
      }
    }

    /**
    * [init loads the function initially]
    */
    function init() {
      vm.loadAll();
      vm.enableUpdateButton = false;
    }

    /**
    * [uploadSignature uploads base64 of signature]
    * @param  {[base64string]} base64Img []
    */
    function uploadSignature(base64Img) {
      vm.uploadingInProgress = true;
      ImageUploadService.uploadImage(base64Img).
      then(function(responseData) {
        vm.signatureUrl = responseData.url;
        NotificationService.success({
          title: 'global.signature',
          message: 'alertMessage.UPLOADED_SUCCESSFULLY'
        });
        interceptToOCRModule();
        cancel();
        vm.uploadingInProgress = false;
      }, function(error) {
        vm.uploadingInProgress = false;
        NotificationService.error({
          title: 'global.signature',
          error: error
        });
      });
    }

    /**
    * [loadAll Gets GRN item Details by PO number]
    */
    function loadAll() {
      BuyerReceivingOrderService.getGRNItemAndBatchDetails(vm.poNumber, {
        deliveryDate: !vm.deliveryDate ? '' : vm.deliveryDate,
      }).then(function(response) {
        vm.checkAlreadyProcessed(response.data);
        vm.itemDetails = response.data;
        vm.grnHardcopyDetails.hardcopyInvoiceNumber = vm.itemDetails.ocrInvoiceNumber ? vm.itemDetails.ocrInvoiceNumber : vm.itemDetails.hardcopyInvoiceNumber;
        orderItemsByPurchaseOrderId(vm.itemDetails.items);
        vm.grBufferValue    = vm.itemDetails.buyer.grBuffer;
        vm.grWeightBufferValue    = vm.itemDetails.buyer.grWeightBuffer;
        vm.minDate = new Date(new Date().getTime() + (vm.itemDetails.buyer.daysAdvanceForGRExpiry * 24) * 60 * 60 * 1000);
        _.map(vm.itemDetails.items, function (item, i) {
            item['quantityMaxlimit'] = item.quantity;
            item['selected'] = true;
            item['rejectItemreason'] = 0;
            item['weightMaxlimit'] = item.weight;
        });

        // initialize header warehouse and bin
        if((vm.itemDetails.ezyInventoryDTO && vm.itemDetails.ezyInventoryDTO.location) && (Array.isArray(vm.itemDetails.ezyInventoryDTO.location.warehouse) && vm.itemDetails.ezyInventoryDTO.location.warehouse.length > 0)) {
          vm.headerWarehouseAndBin.warehouseList = vm.itemDetails.ezyInventoryDTO.location.warehouse;
          vm.headerWarehouseAndBin.binList = vm.itemDetails.ezyInventoryDTO.location.warehouse[0].bin;
        }
        setDefaultWarehouseBin();
        vm.tempSubTotal = vm.itemDetails.acceptTotalAmount;
        tempItemDetails = angular.copy(vm.itemDetails);
      }, function(error) {
      });

      if(!vm.accessForEzyinventory) {
        if(!BuyerDataService.isAdminBuyer()) {
          BuyerReceivingOrderService.checkEzyinventoryAccess().then(function(response) {
            vm.accessForEzyinventory = response.data.accessForEzyinventory;
          }, function(error) {
          });
        }
      }
    }

    function orderItemsByPurchaseOrderId(itemsList) {
      if(Array.isArray(itemsList)) {
        return itemsList.sort(function(a, b) {
          return a.purchaseOrderId - b.purchaseOrderId;
        });
      }
      else {
        return [];
      }
    }

    //set default warehouse on items
    function setDefaultWarehouseBin() {
      if (!!vm.itemDetails.ezyInventoryDTO && !!vm.itemDetails.ezyInventoryDTO.location) {
        var defaultWarehouse = vm.itemDetails.ezyInventoryDTO.location.warehouse.find(function (warehouse) {
          return warehouse.defaultWarehouse;
        });

        if (!!defaultWarehouse) {
          var binList = [];
          var defaultBin = null;
          if (defaultWarehouse.hasBin && defaultWarehouse.bin.length > 0) {
            binList = defaultWarehouse.bin;
            defaultBin = binList.find(function (bin) {
              return bin.defaultBin;
            });
          }

          vm.headerWarehouseAndBin.selectedWarehouseId = defaultWarehouse.id;
          vm.headerWarehouseAndBin.selectedBin = !!defaultBin ? defaultBin.id : undefined;

          vm.itemDetails.items.forEach(function (item) {
            item.selectedWarehouseId = defaultWarehouse.id;
            item.binList = binList;
            if (!!defaultBin) {
              item.selectedBin = defaultBin.id;
            }
          });
        }
      }
    }

    // Redirect back to PO Listing page if cancel po already generated*
    function checkAlreadyProcessed(itemDetails) {
      if (itemDetails.purchaseOrderStatus !== 'PENDING') {
        $state.go('buyer.receiving-orders', {}, { reload: false});
      }
    }

    /**
    * [showAccessModal shows modal to give signature]
    */
    function showSignaturePad(event) {
      vm.otherChargesAmount = vm.otherChargesAmount == null? 0 : vm.otherChargesAmount;
      //validate item list for warehouse and bin
      if(vm.accessForEzyinventory && vm.itemDetails.supplier.visibilityType != vm.adhocOffBoardSupplier){
        if(!vm.validateWarehouseBin(-1, "LIST")){
          NotificationService.simpleErrorToast({
            title: 'error.validation',
            message: 'alertMessage.WAREHOUSE_BIN_VALIDATION'
          });
          return;
        }

        if(!vm.validateBatchList()) {
          NotificationService.simpleErrorToast({
            title: 'error.validation',
            message: 'alertMessage.GRN_BATCH_DETAILS'
          });
          return;
        }

        if(!validateBatchAndGRNquantity()){
          toastr.error('GRN quantity and batch quantity differ for some item!', 'Quantity mismatch');
          return;
        }

        if(!checkUoMAvailable()){
          NotificationService.simpleErrorToast({
            title: 'error.validation',
            message: 'alertMessage.UoM_NOT_AVAILABLE'
          });
          return;
        }
      }


      $mdDialog.show({
        templateUrl: 'app/buyer-modules/receiving-orders/details/buyer.receiving-orders.details.sign-dialog.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    /**
    * [getBuyerResponses get the array of buyer responses]
    * @return {[array]}       [buyer responses]
    */
    function getBuyerResponses(items) {
        _.map(items,function(item){
          if (item.uom.weightRequired) { // weighted items
            if (vm.grWeightBufferValue !== null) {
              item.buyerResponse['weight']= item.weight;
            } else {
              item.buyerResponse['weight']= item.weightMaxlimit;
            }
          } else {
            if (vm.grBufferValue > 0) {
              item.buyerResponse['quantity']= item.quantity;
            } else {
              item.buyerResponse['quantity']= item.quantity;
            }
          }
          item.buyerResponse['buyerRemark'] =  item.buyerResponse['buyerRemark'] == 'Others' ? item.buyerResponse['buyerRemarkOther'] : item.buyerResponse['buyerRemark'];
          item.buyerResponse['price'] = item.price;
        });
        var selectedItems = [];
        _.forEach(items, function(obj){
        if(obj.selected == true){
          selectedItems.push(obj);
        }

      });
      //Pick all buyerResponses array property from list of itemDetails
      var result1 = _.map(selectedItems, _.property('buyerResponse'));
      //Creates an array excluding all given values
      var result2 = _.without(result1, undefined, null);
      //Flattens array a single level deep.
      var buyerResponses = _.flatten(result2);
      return buyerResponses;
    }

    /**
    * [cancel to close the modal]
    */
    function cancel() {
      $mdDialog.cancel();
    }

    /**
     * @description Restructure vm.itemDetails to support DTO format
     */
    function restructureItemDetailsList() {
      var itemBatchDetails = [];
      angular.forEach(vm.itemDetails.items, function (item, index) {
        if(item.selected){
          var buyerResponseQuantity = returnBuyerResponse(index);
          var itemBatch = {
            "itemId": item.itemId,
            "warehouseId": item.selectedWarehouseId,
            "purchaseOrderId": item.purchaseOrderId,
            "isFullReject": item.isFullReject,
            "purchaseOrderNumber": vm.itemDetails.purchaseOrderNumber,
            "locationId": (vm.itemDetails && vm.itemDetails.ezyInventoryDTO && vm.itemDetails.ezyInventoryDTO.location && vm.itemDetails.ezyInventoryDTO.location.id) || null,
            "purchaseOrderUom": item.uom.name,
            "price": item.price,
            "grnQuantity": item.uom.weightRequired ? (buyerResponseQuantity == null ? item.weight : buyerResponseQuantity) : (buyerResponseQuantity == null ? item.quantity : buyerResponseQuantity),
            "binId": item.selectedBin,
            "batchDetails": []
          }
          item.itemBatchDetails && item.itemBatchDetails.forEach(function (batch, batchIndex) {
            var batchDetail = {
              "batchNumber": batch.batchNo,
              "batchQuantity": batch.batchQty,
              "productionDate": (batch.productionDate).toJSON().split('T')[0],
              "expiryDate": batch.expiryDate.toJSON().split('T')[0]
            }
            itemBatch["batchDetails"].push(batchDetail);
          });
          itemBatchDetails.push(itemBatch);
        }
      });
      return itemBatchDetails;
    }

    /**
     * @description validate batch details has entered for all item
     */
    function validateBatchList() {

      var proceedToProcessGRN = true;
      vm.itemDetails.items.forEach(function (item) {
        if (item.managementMethod != "No" && !item.isFullReject) {
          if ((!item.itemBatchDetails || !item.itemBatchDetails.length) && item.isInventory) {
            proceedToProcessGRN = false;
          }
      }
      });
      return proceedToProcessGRN;
    }

    /**
     * @description remove batch details from itemDetails list
     */
    function removeBatchDetail() {
      var removedProperties = {
        "ezyInventoryDTO": vm.itemDetails.ezyInventoryDTO,
        "itemsDetails": []
      }
      vm.itemDetails.items.forEach(function (item) {
        var removedItemProperties = {
          "itemId": item.itemId,
          "selectedWarehouseId": item.selectedWarehouseId,
          "selectedBin": item.selectedBin,
          "itemBatchDetails": item.itemBatchDetails,
          "expiryDate": item.expiryDate
        };
        removedProperties.itemsDetails.push(removedItemProperties);
        delete item.selectedWarehouseId;
        delete item.selectedBin;
        delete item.itemBatchDetails;
        delete item.expiryDate;
      });
      delete vm.itemDetails.ezyInventoryDTO;
      return removedProperties;
    }

    /**
    * [processGoodReceive saving the receiving goods]
    */
    function processGoodReceive() {
    vm.grHardCopyDetails = vm.grnHardcopyDetails;
      var itemBatchDetails = vm.restructureItemDetailsList();
      if(itemBatchDetails.length == 0){
        NotificationService.simpleErrorToast({
          title: 'alertMessage.RECEIVING_ORDER',
          message: 'alertMessage.SELECT_ITEMS_TO_CONTINUE'
        });
        return false;
      }
      // alter remarks for items that have expiry date added
      var hardCopyInvoiceStatus=  vm.itemDetails.grandTotal==0.00?"REJECTED":"NO_INVOICE_UPLOADED";
      concatExpiryOnRemarks();
      var removedProperties = vm.removeBatchDetail();
      var otherCharges = { };
      vm.otherChargesLabel = vm.otherChargesLabel ? vm.otherChargesLabel.replace(':', ''):  vm.otherChargesLabel = 'Other Charges';
      otherCharges[vm.otherChargesLabel] = vm.otherChargesAmount;
      var goodReceiveDTO = {};
      if(vm.grHardCopyDetails!=undefined){
        var goodReceiveDTO = {
            hardCopyInvoiceStatus:hardCopyInvoiceStatus,
            otherCharges: JSON.stringify(otherCharges),
            discount: vm.tempAbsoluteDiscount,
            eSignature: vm.signatureUrl,
            goodsReceivingDTO: getBuyerResponses(vm.itemDetails.items),
            itemBatchDetails: itemBatchDetails,
            goodReceivingDate: vm.goodReceiveDate ? vm.goodReceiveDate : vm.todaysDate,
            deliveryDate: vm.deliveryDate,
            hardcopyInvoiceNumber: vm.grHardCopyDetails.hardcopyInvoiceNumber ? vm.grHardCopyDetails.hardcopyInvoiceNumber : (vm.itemDetails.ocrInvoiceNumber ? vm.itemDetails.ocrInvoiceNumber : vm.itemDetails.hardcopyInvoiceNumber),
            hardcopyInvoiceAmount: vm.grHardCopyDetails.hardcopyInvoiceAmount ? vm.grHardCopyDetails.hardcopyInvoiceAmount : vm.itemDetails.grandTotal,
            hardcopyGst: vm.grHardCopyDetails.hardcopyGst ? vm.grHardCopyDetails.hardcopyGst : vm.itemDetails.addedGST,
            hardcopyInvoiceDate: vm.grHardCopyDetails.hardcopyInvoiceDate ? vm.grHardCopyDetails.hardcopyInvoiceDate : '',
        };
     }
     else{
        goodReceiveDTO = {
            hardCopyInvoiceStatus:hardCopyInvoiceStatus,
            otherCharges  : JSON.stringify(otherCharges),
            discount: vm.tempAbsoluteDiscount,
            eSignature: vm.signatureUrl,
            goodsReceivingDTO: getBuyerResponses(vm.itemDetails.items),
            itemBatchDetails: itemBatchDetails,
            goodReceivingDate: vm.goodReceiveDate ? vm.goodReceiveDate : vm.todaysDate,
            deliveryDate: vm.deliveryDate,
            hardcopyInvoiceNumber: '',
            hardcopyInvoiceAmount: '',
            hardcopyGst: '',
            hardcopyInvoiceDate: '',
        };
     }


     BuyerReceivingOrderService.processGoodReceive(goodReceiveDTO).then(function(response) {
        NotificationService.success({
          title: 'alertMessage.RECEIVING_ORDER',
          message: 'alertMessage.COMPLETED_SUCCESSFULLY'
        });
        $state.go('buyer.receiving-orders', {
          reload: false
        });
      }, function(error) {
        reassignItemProperties(removedProperties);
        removeExpiryOnRemarks();
        NotificationService.error({
          title: 'alertMessage.RECEIVING_ORDER',
          error: error
        });
      });
    }

    function concatExpiryOnRemarks() {
      vm.itemDetails.items = vm.itemDetails.items.map(function (item) {
        // if (item.buyerResponse.buyerAction == 'ACCEPT' && !!item.expiryDate) {
        //   item.buyerResponse.buyerRemark = item.buyerResponse.buyerRemark + " Expiry Date: " + $filter('date')(item.expiryDate, "dd/MM/yyyy");
        // }
        if (!!item.expiryDate) {
            item.buyerResponse.buyerRemark = item.buyerResponse.buyerRemark + " Expiry Date: " + $filter('date')(item.expiryDate, "dd/MM/yyyy");
          }
        return item;
      });
    }

    function removeExpiryOnRemarks() {
      vm.itemDetails.items = vm.itemDetails.items.map(function (item) {
        // if (item.buyerResponse.buyerAction == 'ACCEPT' && !!item.expiryDate) {
        //   item.buyerResponse.buyerRemark = item.buyerResponse.buyerRemark.split(" Expiry Date: ")[0];
        // }
        if (!!item.expiryDate) {
            item.buyerResponse.buyerRemark = item.buyerResponse.buyerRemark.split(" Expiry Date: ")[0];
          }
        return item;
      });
    }

    /**
     * @description reassign properties incase of errors
     */
    function reassignItemProperties(itemProperties) {
      vm.itemDetails.ezyInventoryDTO = itemProperties.ezyInventoryDTO
      vm.itemDetails.items.forEach(function (item, index) {
        if (item.itemId == itemProperties.itemsDetails[index].itemId) {
          item.selectedWarehouseId = itemProperties.itemsDetails[index].selectedWarehouseId;
          item.selectedBin = itemProperties.itemsDetails[index].selectedBin;
          item.itemBatchDetails = itemProperties.itemsDetails[index].itemBatchDetails;
          item.expiryDate = itemProperties.itemsDetails[index].expiryDate;
        }
      });
    }


    /****************OCR Module Integration****************/
    function showInvoiceUploadModal(event) {
     $mdDialog.show({
        templateUrl: 'app/buyer-modules/receiving-orders/details/buyer.recieving-orders.details.invoice-upload.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    // modal that confirms whether to skip invoice upload or not
    function confirmSkipInvoiceUploadModal(event) {
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/receiving-orders/details/buyer.receiving-orders.details.confirm-skip-invoice-upload.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: false
      });
    }

    /*Interceptor which catch requests infront of processGoodReceive() then integrates OCR Module element for Invoice upload*/
    function interceptToOCRModule(){
      vm.otherChargesAmount = vm.otherChargesAmount == null? 0 : vm.otherChargesAmount;
      //validate item list for warehouse and bin
      var isValidItemList=vm.itemDetails && vm.itemDetails.ezyInventoryDTO && vm.itemDetails.ezyInventoryDTO.location && vm.itemDetails.ezyInventoryDTO.location.item && vm.itemDetails.ezyInventoryDTO.location.item.length > 0
      if (vm.accessForEzyinventory && (vm.itemDetails.supplier.visibilityType != vm.adhocOffBoardSupplier && isValidItemList)) {
        if(!vm.validateWarehouseBin(-1, "LIST")){
          NotificationService.simpleErrorToast({
            title: 'error.validation',
            message: 'alertMessage.WAREHOUSE_BIN_VALIDATION'
          });
          return;
        }

        if (!vm.validateBatchList()) {
          NotificationService.simpleErrorToast({
            title: 'error.validation',
            message: 'alertMessage.GRN_BATCH_DETAILS'
          });
          return;
        }

        if(!validateBatchAndGRNquantity()){
          toastr.error('GRN quantity and batch quantity differ for some item!', 'Quantity mismatch');
          return;
        }

        if(!checkUoMAvailable()){
          NotificationService.simpleErrorToast({
            title: 'error.validation',
            message: 'alertMessage.UoM_NOT_AVAILABLE'
          });
          return;
        }

      }

      Auth.hasPermission('accessForOcr')?showInvoiceUploadModal():processGoodReceive();
    }
    /*Skips Invoice Upload-OCR Module element*/


    function skipInvoiceUpload() {
      cancel();
      processGoodReceive();
    }
    /**
    * [testImage description]
    * @param  {[type]} file [description]
    * @return {[type]}      [description]
    */
    function testImage(file) {
      OCRImageUploadService.getImageBase64(file[0].lfFile, function(base64Img) {
        BuyerOCRService.invoiceImgUpload(base64Img).then(function(response) {
          invoiceToMicroService(response.data);
        }, function(error) {
        });
      });
    }

    /*Sending captured Invoice to OCR API*/
    function invoiceToMicroService(invoiceImage) {
      var item = {
        "poNumber": vm.poNumber,
        "invoiceUrl": invoiceImage,
        "invoiceAmount": vm.itemDetails.grandTotal,
        "user": BuyerDataService.getBuyerProfile().buyerUser.user.login,
        "deliveryDate":vm.deliveryDate
      };
       BuyerOCRService.invoiceMicroService(item).then(function(response) {
        NotificationService.success({
          title: 'global.ocrInvoice',
          message: 'alertMessage.UPLOADED_SUCCESSFULLY'
        });
        skipInvoiceUpload();
      }, function(error) {
      });
    }
    /*Calculates GRN According to Buyer Responses*/
    function getGRNfromResponses() {
      var deliveryCharge=(vm.itemDetails.subTotal < vm.itemDetails.minimumOrderForFreeDelivery)?vm.itemDetails.deliveryCharges:0;
      var totAmount=0;
      vm.itemDetails.items.forEach(function(item){
        if(item.buyerResponse.buyerAction==='ACCEPT') {
          delete item.buyerResponse.rejectedQuantity;
        }
        /* var finalQty=item.uom.weightRequired?item.acceptedWeight-(item.buyerResponse.rejectedQuantity?item.buyerResponse.rejectedQuantity:0):item.acceptedQuantity-(item.buyerResponse.rejectedQuantity?item.buyerResponse.rejectedQuantity:0);
         */

        var finalQty;
        if(item.uom.weightRequired){
          if(item.acceptedWeight != item.weight){
            finalQty = item.weight-(item.buyerResponse.rejectedQuantity?item.buyerResponse.rejectedQuantity:0)
          }else{
            finalQty = item.acceptedWeight-(item.buyerResponse.rejectedQuantity?item.buyerResponse.rejectedQuantity:0)
          }
        }else{
           finalQty = item.acceptedQuantity-(item.buyerResponse.rejectedQuantity?item.buyerResponse.rejectedQuantity:0);
        }
        //skip free item price in total amount
        if (!item.freeItem && item.selected) {
          if(item.uom.moqPrice === true){
            totAmount += _.round(((item.quantity/item.moq) * item.price),2);
          }
          else {
            totAmount+= _.round((finalQty*item.price),2);
          }
        }
      });
      var GRNAmount=(_.round(totAmount,2)+deliveryCharge)+vm.itemDetails.addedGST - (vm.tempAbsoluteDiscount || 0);
      return GRNAmount.toFixed(2);
    }
    /****************OCR Module Integration****************/

    /**
     * calculateDiscount calculates the % of discount when type is 'absolute' and calculates the discount's absolute value when type is 'percentage'
     * @param {string} type
     */
    function calculateDiscount(inputType){
      if(vm.itemDetails.subTotal===0){
      }
      switch(inputType) {
       case 'PERCENTAGE':
        vm.itemDetails.discount = (vm.tempPercentDiscount/100)*(vm.tempSubTotal + vm.itemDetails.deliveryCharges || 0) ;
        vm.tempAbsoluteDiscount = +(angular.copy((vm.itemDetails.discount).toFixed(2)))
        var discountedSubtotal = vm.calculateSubTotal(tempItemDetails.items);
        vm.grandTotalAfterDiscount(discountedSubtotal);
        break;
       case 'ABSOLUTE':
        vm.tempPercentDiscount = +(angular.copy(Math.abs(((vm.tempAbsoluteDiscount || 0)*100)/(vm.tempSubTotal + vm.itemDetails.deliveryCharges || 0)).toFixed(2)));
        if(isNaN(vm.tempPercentDiscount)){
          vm.tempPercentDiscount = 0;
        }

        //setting value for *vm.purchaseOrderDetails.invoice.discount* in order to use in calculateInvoiceTotal();
        vm.itemDetails.discount = vm.tempAbsoluteDiscount || 0;
        var discountedSubtotal = vm.calculateSubTotal(tempItemDetails.items);
        vm.grandTotalAfterDiscount(discountedSubtotal);
        break;
      }
    }

      /**
     * calculateOtherCharges
     */
       function calculateOtherCharges(type){
        switch(type){
          case 'AMOUNT':
            vm.otherChargesPerCent = +(angular.copy(Math.abs((vm.otherChargesAmount*100)/(vm.tempSubTotal  )).toFixed(2)));
            var subtotal = vm.calculateSubTotal(tempItemDetails.items);
            vm.grandTotalAfterDiscount(subtotal);
            break;
          case 'PERCENT':
            var amount = (vm.otherChargesPerCent/100)*(vm.tempSubTotal );
            vm.otherChargesAmount = +(angular.copy((amount).toFixed(2)));
            var subtotal = vm.calculateSubTotal(tempItemDetails.items);
            vm.grandTotalAfterDiscount(subtotal);
            break;
        }
      }

    /**
     * grandTotalAfterDiscount calculates the grandTotal after discount is calculated
     * @param {Number} discountedSubtotal
     */
    function grandTotalAfterDiscount(discountedSubtotal){
      if(vm.itemDetails.supplier.visibilityType == 'ADHOC_OFF_BOARD_SUPPLIER' && vm.itemDetails.adhocPOGstEnabled){
        // unit price inclusive of tax
        vm.itemDetails.grandTotal = discountedSubtotal;
        vm.itemDetails.addedGST = vm.itemDetails.grandTotal - (vm.itemDetails.grandTotal / (1 + (vm.itemDetails.gst / 100)));
        vm.itemDetails.subTotal = vm.itemDetails.grandTotal - vm.itemDetails.addedGST;
        return false;
      }
      vm.itemDetails.subTotal = discountedSubtotal;
      if(vm.itemDetails.subTotal === 0){
        vm.itemDetails.deliveryCharges = 0;
      }
      if(vm.itemDetails.supplier.tax === 'Active' && vm.itemDetails.gst !== null){
        if(vm.itemDetails.subTotal < vm.itemDetails.minimumOrderForFreeDelivery){
          vm.itemDetails.addedGST = ( vm.itemDetails.gst / 100) * (vm.itemDetails.subTotal+vm.itemDetails.deliveryCharges);
          vm.itemDetails.adhocPOGstEnabled != null ? (vm.itemDetails.adhocPOGstEnabled ? vm.itemDetails.addedGST : vm.itemDetails.addedGST = 0) :  vm.itemDetails.addedGST;
        }
        else{
          vm.itemDetails.addedGST = ( vm.itemDetails.gst / 100) * (vm.itemDetails.subTotal);
          vm.itemDetails.adhocPOGstEnabled != null ? (vm.itemDetails.adhocPOGstEnabled ? vm.itemDetails.addedGST : vm.itemDetails.addedGST = 0) :  vm.itemDetails.addedGST;
        }
      }
      if(vm.itemDetails.subTotal < vm.itemDetails.minimumOrderForFreeDelivery){
        vm.itemDetails.grandTotal = _.round((vm.itemDetails.addedGST  + vm.itemDetails.subTotal + vm.itemDetails.deliveryCharges),2);
      }else{
      vm.itemDetails.grandTotal = _.round((vm.itemDetails.addedGST  +vm.itemDetails.subTotal),2);
     }
    }

/**
 * [isWeightChanged check whether weight is changed or else do not call amount calculation methods]
 * @param  {[type]}  index [index of the item for which weight is changed]
 * @param  {[type]}  param [description]
 * @return {Boolean}       [description]
 */
    function isWeightChanged(index){
      if(vm.itemDetails.items[index].weight!= tempItemDetails.items[index].weight){
        vm.changeWeight(index);
      }

    }

    function isquantityChanged(index){
        if(vm.itemDetails.items[index].quantity!= tempItemDetails.items[index].quantity){
          vm.changeQuantity(index);
        }

      }

    /*
    * get the warehouse from ezyinventory
    */

    function getInventoryWarehouse(){
        vm.selectedWarehouseName = [];
        BuyerReceivingOrderService.getInventoryWarehouse()
         .then(function(response){
            vm.inventoryWarehouseList = response.data;
            vm.outletName = vm.itemDetails.buyerBusiness.businessOutletName;
            angular.forEach(vm.inventoryWarehouseList, function (value, key) {
            if(vm.outletName == value.location.name){
            vm.selectedWarehouseName.push(value);
                }
            });
            angular.forEach(vm.selectedWarehouseName,function(value){
            vm.hasBin = value.hasBin;
                });
         }, function(error){
          });
    }

    /*
    * get the bin fromm ezyinventory
    */

    function getInventoryBins(){
        if(vm.selectedWarehouseName){
            vm.filteredBin = [];
            BuyerReceivingOrderService.getInventoryBins()
            .then(function(response){
                vm.inventoryBinList = response.data;
                angular.forEach(vm.inventoryBinList, function (value, key) {
                    if(vm.selectedWarehouseId == value.warehouse.id){
                        vm.filteredBin.push(value);
                    }
                });
            }, function(error){
               });
        } else {
                    vm.checkWarehouseSelection();
               }
    }

    /*
    * display error if warehouse not gets selected
    */

    function checkWarehouseSelection() {
        NotificationService.simpleErrorToast({
            title: 'alertMessage.ERROR',
            message:'alertMessage.SELECT_WAREHOUSE'
        });
        return false;

    }




        /**
     * [calculateGst description]
     * If invoice generated, then gst should autopopulate on changing amount
     * gstAmount = amount - (amount / (1 + gstValue))
     */
    function calculateGst() {
        if (vm.itemDetails.invoiceStatus !== 'PROCESSED' && vm.itemDetails.gst) {
          var amount = vm.grnHardcopyDetails.hardcopyInvoiceAmount ? parseFloat(angular.copy(vm.grnHardcopyDetails.hardcopyInvoiceAmount)) : 0;
          var gstValue = vm.itemDetails.gst / 100;
          var gstAmount = amount - (amount / (1 + gstValue));
          vm.grnHardcopyDetails.hardcopyGst = $filter('decimalNumber')(gstAmount, 2);
        }
      }


//Free item reject when promotionItem rejected

function isFreeItemFound(item,i)
{
if(item){
  index=i;
  selectedItem=item;
  var freeItemFound = _.find(vm.itemDetails.items, function(items) {
    return items.freeItem;
   });
   freeItemFound?(vm.promotions.length>0)?getPromotionData(): getBuyerPromotions():'';
}

}

function getBuyerPromotions() {

  PromotionService.getAllPromotionItemsForBuyer({
    page: 0,
    size: 10,
    sort: 'effectiveStartDate,asc',
    searchTerm: '',
    startDate: new Date(),
    endDate: '',
    flag:"BUY_NOW",
    buyerBusinessId: vm.buyerBusinessId
  }).then(function(response) {
    vm.showPromotionTicker = true;
    vm.promotions = response.data;
    getPromotionData();
   }, function(error) {
    vm.promotions = null;
  })
}

function getPromotionData()
{
  if(selectedItem){
     var activePromotion = _.find(vm.promotions, function(data) {
     return data.promotion.promotionItem.id === selectedItem.itemId;
    });
    if(activePromotion)
    {
     activePromotion=activePromotion.promotion;
     var activePromotionFreeItem=activePromotion.freeItem;
     var applicablePromotionQuantity=activePromotion.applicablePromotionQuantity;
     var freeQuantity=activePromotion.freeQuantity;
     var actualQuantity=selectedItem.uom.weightRequired?selectedItem.weight-selectedItem.buyerResponse.rejectedQuantity:selectedItem.quantity-selectedItem.buyerResponse.rejectedQuantity;
     freeItemAppliedToIndex= _.findIndex(vm.itemDetails.items, function(items) {
      return (items.itemId==activePromotionFreeItem.id && items.freeItem);
    });
     var freeItemAppliedTo = vm.itemDetails.items[freeItemAppliedToIndex];
     var freeItemAppliedToquantity=freeItemAppliedTo.uom.weightRequired? freeItemAppliedTo.buyerResponse.weight:freeItemAppliedTo.buyerResponse.quantity;
    var freeItemRejected=actualQuantity>=applicablePromotionQuantity?(freeQuantity/applicablePromotionQuantity)*actualQuantity:freeItemAppliedToquantity;

     if(actualQuantity>=applicablePromotionQuantity)
     {
      freeItemRejected=((Math.floor(actualQuantity/applicablePromotionQuantity))*freeQuantity);
        freeItemRejected=  (freeItemAppliedToquantity-freeItemRejected);
        freeItemRejected = Math.floor(freeItemRejected);
        freeItemRejected=freeItemRejected<0?0:freeItemRejected;
     }
 if(freeItemRejected>0)
 {
  var freeItemBuyerResponse=freeItemAppliedTo.buyerResponse;
     freeItemBuyerResponse.buyerAction=selectedItem.buyerResponse.buyerAction;
     freeItemBuyerResponse.buyerRemark=selectedItem.buyerResponse.buyerRemark;
     freeItemBuyerResponse.rejectedQuantity=freeItemRejected;
     vm.itemDetails.items[freeItemAppliedToIndex].buyerResponse=freeItemBuyerResponse;
 }


    }
  }
}

function isPriceChanged(index){
  if(vm.itemDetails.items[index].price!= tempItemDetails.items[index].price){
    vm.changeQuantity(index);
  }
}

    //OCR add new row
    function addNewGRN() {
      var itemDTO = {
        id: 0,
        itemCode: "",
        itemName: "",
        uom: {
          name: "",
          weightRequired: true
        },
        weight: 0,
        quantity: 0,
        poUnitPrice: 0,
        grnUnitPrice: 0,
        currency: "",
        acceptedWeight: 0,
        acceptedQuantity: 0,
        receivedDate: new Date(),
        buyerRemarks: ""
      };
      vm.newGRNItemDTO.items.push(itemDTO);
    }

    function itemCodeChanged(selectedItem, index) {

      var temp = angular.copy(selectedItem);
      vm.newSelectedItems.push(temp);
      vm.newGRNItemDTO.items[index].currency = selectedItem.currency;
      vm.newGRNItemDTO.items[index].uom.name = selectedItem.uom.name;
      vm.newGRNItemDTO.items[index].uom.weightRequired = selectedItem.uom.weightRequired;
      vm.newGRNItemDTO.items[index].uom.moqPrice = selectedItem.uom.moqPrice;
      vm.newGRNItemDTO.items[index].price = selectedItem.price;
      vm.newGRNItemDTO.items[index].moq = selectedItem.moq;
      vm.newGRNItemDTO.items[index].poUnitPrice = selectedItem.price;
      vm.newGRNItemDTO.items[index].grnUnitPrice = selectedItem.price;
      vm.newGRNItemDTO.items[index].id = selectedItem.id;
      vm.newGRNItemDTO.items[index].itemCode = selectedItem.itemCode;
      validateItem(selectedItem);
      if (vm.isDuplicate) {
        NotificationService.simpleErrorToast({
          title: 'alertMessage.ITEM_EXISTS',
          message: 'error.FIELD_CONTAINS_INVALID_DATA'
        });
        vm.newGRNItemDTO.items.splice(index, 1);
        vm.newSelectedItems.splice(index, 1);
        vm.isDuplicate = false;
      }
      vm.checkGlCode(selectedItem, index);
    }

    // Delete new item
    function deleteItemByIndex(index) {
      vm.newGRNItemDTO.items.splice(index, 1);
      vm.newSelectedItems.splice(index, 1);
    }

    // validation for duplicate items
    function validateItem(selectedItem) {
      vm.isDuplicate = false;
      if (vm.newGRNItemDTO.items.length > 1) {
        for (var index = 0; index < (vm.newGRNItemDTO.items.length - 1); ++index) {
          if (selectedItem.id == vm.newGRNItemDTO.items[index].id)
            vm.isDuplicate = true;
        }
      }
    }

    // search items by business outlet and supplier id
    function searchForItems(queryType, queryText) {
      var deferred = $q.defer();
      if (!queryText || JSON.stringify(vm.itemDetails) === JSON.stringify({})) {
        return;
      }
      else {
        vm.itemSearchQuery.queryField = queryType;
        vm.itemSearchQuery.queryText = queryText;
        vm.itemSearchQuery.businessIdList = [vm.itemDetails.buyerBusiness.id];
        vm.itemSearchQuery.buyerIdList = null;
        vm.itemSearchQuery.supplierId = vm.itemDetails.supplier.id;
        BuyerItemService.searchAllItems(vm.itemSearchQuery)
          .then(function (response) {
               var filteredItems =UtilService.checkDuplicateItem(vm.itemDetails.items,response.data,0)
               deferred.resolve($filter('orderBy')(filteredItems, 'itemName'));
          }, function (error) {
            deferred.reject(error);
          });
      }
      return deferred.promise;
    }

    function checkGlCode(item, index) {
      if (vm.accessForGlModule) {
        var selectedItemGroupId = item.itemGroup.id;
        var purchaseOrderTypeId = vm.itemDetails.items[0].glCodeMappings ? vm.itemDetails.items[0].glCodeMappings.purchaseOrderTypes.id : null;
        var glMappingObject = {
          "itemGroupId": selectedItemGroupId,
          "purchaseOrderTypeId": purchaseOrderTypeId
        };
        GlMappingService.getAllGlMappings({}, glMappingObject).then(function (response) {
          vm.totalItems = response.headers('X-Total-Count');
          vm.glMappings = response.data;
          if (vm.glMappings.length > 0) {
            vm.errorGlcode = UtilService.removeItemOnce(vm.errorGlcode, index);
          }
          else {
            vm.errorGlcode = UtilService.addItemOnce(vm.errorGlcode, index);
            toastr.error('Purchase order type ' + vm.itemDetails.items[0].glCodeMappings.purchaseOrderTypes.name + ' is not mapped with item group ' + item.itemGroup.name + '', 'GL Mapping Listing', NotificationService.getToastrOptions());

          }
        }, function (error) {
          NotificationService.error({
            title: 'GL Mapping Listing',
            error: error
          });
          vm.errorGlcode = UtilService.addItemOnce(vm.errorGlcode, index);
        });
      }

    }

    function computeItemCost(item, itemType) {
      var qty;
      if (itemType != "NEW") {
        qty = item.uom.weightRequired ? item.acceptedWeight : item.acceptedQuantity;
      }
      else {
        qty = item.uom.weightRequired ? item.weight : item.quantity;
      }
      var totalPrice = 0;
      if (item.uom.moqPrice === true)
        totalPrice = (qty / item.moq) * item.grnUnitPrice;
      else
        totalPrice = qty * item.grnUnitPrice;
      item.poAmount = _.round(totalPrice, 2);
    }

    // save newly added items
    function saveGRNNewItems(enableUpdateButton) {
      if (vm.newGRNItemDTO.items.length > 0) {
        if (vm.isDuplicate) {
          NotificationService.simpleErrorToast({
            title: 'alertMessage.ITEM_EXISTS',
            message: 'error.FIELD_CONTAINS_INVALID_DATA'
          });
          return;
        }
        var newItemJSON = {
          "poNumber": vm.poNumber,
          "deliveryDate": vm.deliveryDate,
          "purchaseOrderTypeId": vm.itemDetails.items[0].glCodeMappings ? vm.itemDetails.items[0].glCodeMappings.purchaseOrderTypes.id : null,
          "items": []
        };
        angular.forEach(vm.newGRNItemDTO.items, function (value, key) {
          var item = {};
          item.itemId = value.id;
          item.price = value.grnUnitPrice;
          item.quantity = value.uom.weightRequired ? null : value.quantity;
          item.weight = value.uom.weightRequired ? value.weight : null;
          item.buyerRemarks = value.buyerRemarks;
          item.error=checkIsItemZero(value);
          newItemJSON.items.push(item);
        });
        if (_.find(newItemJSON.items, { error: true })) {
          toastr.warning("Please add quantity values greater than 0 ");
            return; // Stop further processing if any item has an error
          }
        BuyerOCRService.saveNewItemsGRN(newItemJSON).then(function (response) {
          vm.newGRNItemDTO = {
            items: []
          };
          NotificationService.success({
            title: 'global.itemDetails',
            message: 'alertMessage.ADDED_SUCCESSFULLY'
          });
          init();
        }, function (error) {
          NotificationService.error({
            title: 'global.userAccessTypes.ocrStaffInterface',
            error: error
          });
        });
      }
    }
    
    function checkIsItemZero(value) {
      var qty = value.uom.weightRequired ? value.weight : value.quantity;
      return qty <= 0 ;
  }
    

}
})();
