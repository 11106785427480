/**
 * @Author: Ajay
 * @Date:   04-Dec-2017 10:19 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.item-mapping.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2018-06-29T12:54:39+05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerItemMappingController', BuyerItemMappingController);

  BuyerItemMappingController.$inject = ['$scope', '$state', '$stateParams', 'toastr', 'pagingParams', 'BuyerDataService', 'BuyerItemService', 'BuyerSupplierMappingService', 'BuyerBusinessService', 'BuyerItemMapService', 'paginationConstants', 'NotificationService', 'BuyerItemOutletMapService','CartService','$mdDialog'];

  function BuyerItemMappingController ($scope, $state, $stateParams, toastr, pagingParams, BuyerDataService, BuyerItemService, BuyerSupplierMappingService, BuyerBusinessService, BuyerItemMapService, paginationConstants, NotificationService, BuyerItemOutletMapService,CartService,$mdDialog) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.allSelected = false;
    vm.sort = sort;
    vm.loadAll = loadAll;
    vm.deleteMapping = deleteMapping;
    vm.mappedItemSearch = '';
    vm.deleteMappingByIds = deleteMappingByIds;
    vm.checkAllRows = checkAllRows;
    vm.getSelectedRows = getSelectedRows;
    vm.getSelectedItemsDetailList = getSelectedItemsDetailList;
    vm.unmapMultipleItems = unmapMultipleItems;
    vm.selected = "itemName";
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.closeDialog=closeDialog;
    vm.clearCart=clearCart;
    vm.showConfirm=showConfirm;
    function checkAllRows() {
      vm.mappedItems.forEach(function (item) {
        item.isSelected = vm.allSelected;
      });
    }

    function getSelectedRows() {
      if (vm.mappedItems && vm.mappedItems.length > 0) {
        var selectedIdList = vm.mappedItems.filter(function(item) {
          return item.isSelected;
        });
      }
      return selectedIdList || [];
    }

    function getSelectedItemsDetailList() {
      var selectedItemsDetailList = [];
      var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      vm.mappedItems.forEach(function (item) {
        if(item.isSelected) {
          var itemDetail = {
            buyerId: buyerId,
            itemBusinessMappingId: item.itemBusinessMappingId,
            itemId: item.id,
            userType: 'BUYER'
          }
          selectedItemsDetailList.push(itemDetail);
        }
      });
      return selectedItemsDetailList;
    }

    function unmapMultipleItems() {
      var itemDetailList = getSelectedItemsDetailList();
      var itemIds = _.map(itemDetailList, function(item) {
        return item.itemId;
          });
          var matchingItemsData = CartService.checkMultipleItemsInCart(itemIds);
          if (matchingItemsData.isAvailable) {
            // If items are found in the cart, show an alert or modal (example using alert)
            var itemNames = _.map(matchingItemsData.matchingItems, function(item) {
              return item.itemName;
            }).join(', ');
            matchingItemsData.itemName=itemNames;
            matchingItemsData["customErrorMessage"]="Can't unmap the item as it is already added in the cart.";
            showDialog(matchingItemsData)
            return;
          }
      BuyerItemOutletMapService.unmapMultipleItems(itemDetailList).then(function(response){
        NotificationService.success({
          title: 'Item Mapping',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      },function(error){
        const customErrorMessage = error.headers && error.headers('X-ezyprocureApp-error-message');
if (customErrorMessage) {
        var item={customErrorMessage:"Can't unmap the item as it is already added in the cart of another user."}
      showDialog(item);
      }else{NotificationService.error({
        title: 'Item Mapping Deletion',
        error: error
      });}
        
      });
    }

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    // Load All supplier items which are mapped
    function loadAll (search) {
      if (!search) {
        vm.mappedItemSearch = '';
      }
      BuyerItemMapService.getAllMappedItems({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.mappedItemSearch,
        searchBy: vm.selected
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.mappedItems = response.data;
      },function(error){
      });
    }

    //delete a mapping
    function deleteMapping(itemBusinessMappingId) {
      BuyerItemOutletMapService.deleteMappingByItemBusinessMappingId(itemBusinessMappingId).then(function(response){
        NotificationService.success({
          title: 'Item Mapping',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      },function(error){
        NotificationService.error({
          title: 'Item Mapping Deletion',
          error: error
        });
      });
    }

    /**
     * [deleteMappingByIds by ids ]
     * @param {*} itemId 
     */
    function deleteMappingByIds(itemBusinessMappingId, itemId) {
      var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      var matchingItemData= CartService.checkMultipleItemsInCart([itemId]);
      if(matchingItemData.isAvailable){
        var itemNames = _.map(matchingItemData.matchingItems, function(item) {
          return item.itemName;
        }).join(', ');
        matchingItemData["itemName"]=itemNames;
        matchingItemData["customErrorMessage"]="Can't unmap the item as it is already added in the cart.";
        showDialog(matchingItemData);
        return;
      }
      BuyerItemOutletMapService.deleteMappingByItemBusinessMappingIds(itemBusinessMappingId, buyerId, itemId, 'BUYER').then(function(response){
        NotificationService.success({
          title: 'Item Mapping',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      },function(error){
        const customErrorMessage = error.headers && error.headers('X-ezyprocureApp-error-message');
        if (customErrorMessage) {
                  var item={customErrorMessage:"Can't unmap the item as it is already added in the cart of another user."}
                showDialog(item);
                }else{
        NotificationService.error({
          title: 'Item Mapping Deletion',
          error: error
        });
      }
      });
    }
    function showConfirm(ev, itemBusinessMappingId, itemId) {
      var confirm = $mdDialog.confirm()
        .title('Do you really want to unmap this item?')
        .textContent('This action cannot be reverted.')
        .targetEvent(ev)
        .ok('Unmap')
        .cancel('Cancel');
    
      $mdDialog.show(confirm).then(function() {
        if (!itemBusinessMappingId || !itemId) {
          unmapMultipleItems(); // Call this if either argument is null or undefined
        } else {
          deleteMappingByIds(itemBusinessMappingId, itemId); // Otherwise, delete by ID
        }
      }, function() {
        // Cancel action (No need to do anything)
      });
    }
    



    function showDialog(item) {
      vm.item=item;
      // Define the dialog template
      $mdDialog.show({
        templateUrl: 'app/core/common-templates/itemDialogTemplate.html',
        controller: function() {
            return vm;
        },
        controllerAs: 'vm',
        escapeToClose: false,
        // targetEvent: event,
        clickOutsideToClose: false
    });
    };
    function closeDialog() {
      $mdDialog.hide(); // This will close the dialog
    }
     function clearCart() {
       $state.go('buyer.cart-checkout', {}, { reload: false});
       $mdDialog.hide();
    };

    vm.loadAll();

  }
})();
