/*
 * @Author: Jayalekshmi 
 * @Project: Ezyprocure
 * @Filename: admin.amendments.services.js
 * @Date: 2020-03-04 17:44:12 
 * @Last Modified By : Jayalekshmi
 * @Last Modified time: 2020-03-05 14:51:41
 */

(function() {
    'use strict';

    angular.module('ezyprocureApp').factory('AdminAmendmentsService', AdminAmendmentsService);

    AdminAmendmentsService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

    function AdminAmendmentsService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {
        var adminAmendmentsService = {
            runAmendments: runAmendments,
            changeDeliveryDate: changeDeliveryDate,
            changeItemQty: changeItemQty,
            changePoItemPrice: changePoItemPrice,
            changeGrnStatusToPending: changeGrnStatusToPending,
            changePoOcrAcceptanceStatusToPending: changePoOcrAcceptanceStatusToPending,
            changeInvoiceStatusToPending: changeInvoiceStatusToPending,
            cancelPo: cancelPo,
            deletePrAndPo: deletePrAndPo,
            removePoItem: removePoItem,
            poAddItem: poAddItem,
            changePOStatus: changePOStatus,
            changePoOrderDate: changePoOrderDate,
            searchAllItems: searchAllItems,
            getAllItemList: getAllItemList,
            getBuyersBySupplier:getBuyersBySupplier,
            getSuppliers:getSuppliers,
            getOldUomBySupplierIdAndBuyerIdAndItemCode:getOldUomBySupplierIdAndBuyerIdAndItemCode,
            updateUOMByItemCode:updateUOMByItemCode,
            getOutletCountByBuyerId:getOutletCountByBuyerId,
            setOutletCountByBuyerId:setOutletCountByBuyerId
        };

        return adminAmendmentsService;

        //Get all supplier items which are mapped to a buyer business outlets. Search query as parameter.
        function searchAllItems(searchFilter) {
            var deferred = $q.defer();
            // console.log('before call api');
            $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'items/buyer-search', searchFilter)
                .then(function(data) {
                    console.log('before call api', data);
                    deferred.resolve(data);
                }).catch(function(message) {
                    deferred.reject(message);
                });
            return deferred.promise;
        }

        /**
         * [runAmendments Running amendments]
         * @param {Object} params 
         * @param {Object} data 
         */
        function runAmendments(params, data) {
            var deferred = $q.defer();
            $http({
                    url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin/amendments/po-no-trace',
                    method: 'POST',
                    params: params,
                    data: data
                })
                .then(function(data) {
                    deferred.resolve(data.data);
                })
                .catch(function(message) {
                    deferred.reject(message);
                });
            return deferred.promise;
        }

        /**
         * [runAmendments Running amendments]
         * @param {Object} data 
         */
        function changeDeliveryDate(data) {
            var deferred = $q.defer();

            $http({
                    url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin-amendments/changeDeliveryDate',
                    method: 'PUT',
                    params: data,
                    // data:data
                    // [data:data]

                })
                .then(function(data) {
                    deferred.resolve(data.data);
                })
                .catch(function(message) {
                    deferred.reject(message);
                });
            return deferred.promise;
        }

        /**
         * [runAmendments Running amendments]
         * @param {Object} data 
         */
        function changeItemQty(data) {
            var deferred = $q.defer();
            $http({
                    url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin-amendments/changePoItemQuantity',
                    method: 'PUT',
                    // params: params,
                    data: data
                })
                .then(function(data) {
                    deferred.resolve(data.data);
                })
                .catch(function(message) {
                    deferred.reject(message);
                });
            return deferred.promise;
        }

        /**
         * [runAmendments Running amendments]
         * @param {Object} data 
         */
        function changePoItemPrice(data) {
            var deferred = $q.defer();
            $http({
                    url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin-amendments/changePoItemPrice',
                    method: 'PUT',
                    // params: params,
                    data: data
                })
                .then(function(data) {
                    deferred.resolve(data.data);
                })
                .catch(function(message) {
                    deferred.reject(message);
                });
            return deferred.promise;
        }

        /**
         * [runAmendments Running amendments]
         * @param {Object} data 
         */
        function changeGrnStatusToPending(data) {
            var deferred = $q.defer();
            $http({
                    url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin-amendments/changeGrnStatusToPending',
                    method: 'PUT',
                    // params: params,
                    data: data
                })
                .then(function(data) {
                    deferred.resolve(data.data);
                })
                .catch(function(message) {
                    deferred.reject(message);
                });
            return deferred.promise;
        }

        /**
         * [runAmendments Running amendments]
         * @param {Object} data 
         */
        function changePoOcrAcceptanceStatusToPending(data) {
            var deferred = $q.defer();
            $http({
                    url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin-amendments/changePoOcrAcceptanceStatusToPending',
                    method: 'PUT',
                    // params: params,
                    data: data
                })
                .then(function(data) {
                    deferred.resolve(data.data);
                })
                .catch(function(message) {
                    deferred.reject(message);
                });
            return deferred.promise;
        }

        /**
         * [runAmendments Running amendments]
         * @param {Object} data 
         */
        function changeInvoiceStatusToPending(data) {
            var deferred = $q.defer();
            $http({
                    url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin-amendments/changeInvoiceStatusToPending',
                    method: 'PUT',
                    // params: params,
                    data: data
                })
                .then(function(data) {
                    deferred.resolve(data.data);
                })
                .catch(function(message) {
                    deferred.reject(message);
                });
            return deferred.promise;
        }

        /**
         * [runAmendments Running amendments]
         * @param {Object} data 
         */
        function cancelPo(data) {
            var deferred = $q.defer();
            $http({
                    url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin-amendments/cancelPo',
                    method: 'PUT',
                    // params: params,
                    data: data
                })
                .then(function(data) {
                    deferred.resolve(data.data);
                })
                .catch(function(message) {
                    deferred.reject(message);
                });
            return deferred.promise;
        }

        /**
         * [runAmendments Running amendments]
         * @param {Object} data 
         */
        function deletePrAndPo(data) {
            var deferred = $q.defer();
            $http({
                    url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin-amendments/deletePrAndPo',
                    method: 'PUT',
                    // params: params,
                    data: data
                })
                .then(function(data) {
                    deferred.resolve(data.data);
                })
                .catch(function(message) {
                    deferred.reject(message);
                });
            return deferred.promise;
        }

        /**
         * [runAmendments Running amendments]
         * @param {Object} data 
         */
        function removePoItem(data) {
            var deferred = $q.defer();
            $http({
                    url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin-amendments/removePoItem',
                    method: 'PUT',
                    // params: params,
                    data: data
                })
                .then(function(data) {
                    deferred.resolve(data.data);
                })
                .catch(function(message) {
                    deferred.reject(message);
                });
            return deferred.promise;
        }

        /**
         * [runAmendments Running amendments]
         * @param {Object} data 
         */
        function poAddItem(data) {
            var deferred = $q.defer();
            $http({
                    url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin-amendments/poAddItem',
                    method: 'PUT',
                    // params: params,
                    data: data
                })
                .then(function(data) {
                    deferred.resolve(data.data);
                })
                .catch(function(message) {
                    deferred.reject(message);
                });
            return deferred.promise;

        }

        /**
         * [runAmendments Running amendments PO add item]
         * @param {Object} data 
         */
        function getAllItemList(data) {
            var deferred = $q.defer();
            $http({
                    url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'purchaseOrdersList',
                    // url: API_CONSTANTS.serviceBase + `api/purchaseOrdersList?purchaseOrderNumber=${data.purchaseOrderNumber}&deliveryDate=${data.deliveryDate}`,
                    method: 'GET',
                    params: data,
                    // data: data
                })
                // $http({
                //         url: `http://localhost:8080/api/purchaseOrdersList?purchaseOrderNumber=121200001390&deliveryDate=2022-04-19T16:00:00.000Z`
                //     })
                .then(function(data) {
                    deferred.resolve(data.data);
                })
                .catch(function(message) {
                    deferred.reject(message);
                });
            return deferred.promise;
        }

        /**
         * [runAmendments Running amendments]
         * @param {Object} data 
         */
        function changePOStatus(data) {
            var deferred = $q.defer();
            $http({
                    url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin-amendments/changePOStatus',
                    method: 'PUT',
                    // params: params,
                    data: data
                })
                .then(function(data) {
                    console.log("2222", data)
                    deferred.resolve(data.data.message);
                })
                .catch(function(message) {
                    deferred.reject(message);
                });
            return deferred.promise;
        }

        /**
         * [runAmendments Running amendments]
         * @param {Object} data 
         */
        function changePoOrderDate(data) {
            var deferred = $q.defer();
            $http({
                    url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin-amendments/changePoOrderDate',
                    method: 'PUT',
                    // params: params,
                    data: data
                })
                .then(function(data) {
                    deferred.resolve(data.data);
                })
                .catch(function(message) {
                    deferred.reject(message);
                });
            return deferred.promise;
        }

        function getSuppliers()
        {
         var deferred = $q.defer();
         $http({
                 url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin-amendments/supplier/all',
                 method: 'GET',
                  
             })
             .then(function(data) {
                 deferred.resolve(data.data);
             })
             .catch(function(message) {
                 deferred.reject(message);
             });
             return deferred.promise;
        }
       
        function getBuyersBySupplier(supplierId)
        {
         var deferred = $q.defer();
         $http({
                 url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin-amendments/buyers/by-supplier/'+supplierId,
                 method: 'GET',
                  
             })
             .then(function(data) {
                 deferred.resolve(data.data);
             })
             .catch(function(message) {
                 deferred.reject(message);
             });
             return deferred.promise;
        }
        function getBuyers()
        {
         var deferred = $q.defer();
         $http({
                 url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyers/',
                 method: 'GET',
                  
             })
             .then(function(data) {
                 deferred.resolve(data.data);
             })
             .catch(function(message) {
                 deferred.reject(message);
             });
             return deferred.promise;
        }
 
 
         function getOldUomBySupplierIdAndBuyerIdAndItemCode(supplierId,buyerId, itemCode) {
         var deferred = $q.defer();
         $http({
             url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin-amendments/itemUom/' + supplierId + '/'+ buyerId + '/' + itemCode,
             method: 'GET',
         })
         .then(function(response) {
             deferred.resolve(response.data);
         })
         .catch(function(error) {
             deferred.reject(error);
         });
         return deferred.promise;
     };


/**
 * Retrieves the outlet count for a specific buyer ID.
 *
 * This function makes a GET request to the back-end API endpoint to fetch the count of outlets 
 * associated with the given buyer ID. The response contains the outlet count and a success message.
 *
 * @param {number} buyerId - The ID of the buyer for which the outlet count is required.
 * @returns {Promise} A promise that resolves with an object containing:
 *                    - outletCount: {number} The count of outlets.
 *                    - message: {string} A success message from the server.
 *                    If the request fails, the promise is rejected with an error.
 */
function getOutletCountByBuyerId(buyerId) {
    var deferred = $q.defer();
    $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyers/outlet/count',
        method: 'GET',
        params: { buyerId: buyerId }, // Pass buyerId as a query parameter
    })
    .then(function(response) {
        // Extract outletCount and message from the response
        var responseData = {
            outletCount: response.data.outletCount,
            availableCount: response.data.availableCount,
            message: response.data.message
        };
        deferred.resolve(responseData);
    })
    .catch(function(error) {
        deferred.reject(error);
    });
    return deferred.promise;
}

 /**
 * Sets a new outlet count for a specific buyer ID.
 *
 * This function makes a PUT request to the back-end API endpoint to update the outlet count 
 * for the given buyer ID. The response contains the updated outlet count and a success message.
 *
 * @param {number} buyerId - The ID of the buyer for which the outlet count is to be updated.
 * @param {number} newOutletCount - The new outlet count to be set.
 * @returns {Promise} A promise that resolves with an object containing:
 *                    - outletCount: {number} The updated count of outlets.
 *                    - message: {string} A success message from the server.
 *                    If the request fails, the promise is rejected with an error.
 */
function setOutletCountByBuyerId(buyerId, newOutletCount) {
    var deferred = $q.defer();
    $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyers/outlet/count',
        method: 'PUT',
        params: { buyerId: buyerId }, // Pass buyerId as a query parameter
        data: { outletCount: newOutletCount }  // Send new outlet count in the request body
    })
    .then(function(response) {
        // Extract outletCount and message from the response
        var responseData = {
            outletCount: response.data.outletCount,
            message: response.data.message
        };
        deferred.resolve(responseData);
    })
    .catch(function(error) {
        deferred.reject(error);
    });
    return deferred.promise;
}

        function updateUOMByItemCode(data)
        {
         var deferred = $q.defer();
         $http({
                 url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin-amendments/itemUomUpdate',
                 method: 'PUT',
                 data: data
                 })
             .then(function(data) {
                 deferred.resolve(data.data);
             })
             .catch(function(message) {
                 deferred.reject(message);
             });
             return deferred.promise;
        }
        
    }
})();