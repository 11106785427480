/**
* @Author: Allan
* @Date:   2018-09-22 13:00:30
* @Filename: ezypayment.initiate-payment.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-10-15T16:00:54+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzypaymentInitiatePaymentController', EzypaymentInitiatePaymentController);

  EzypaymentInitiatePaymentController.$inject = ['$mdDialog', '$state','$filter', '$timeout', 'EzypaymentInitiatePaymentService', 'paginationConstants', 'toastr', 'BuyerOCRService',  'SupplierDataService', 'BuyerSupplierMappingService','UTCDateService', 'NotificationService', 'GLOBAL_CONSTANTS', 'DuePaymentInvoicesService', 'InvoiceService', 'CardsService'];

  function EzypaymentInitiatePaymentController($mdDialog , $state, $filter, $timeout, EzypaymentInitiatePaymentService, paginationConstants, toastr, BuyerOCRService, SupplierDataService, BuyerSupplierMappingService, UTCDateService, NotificationService, GLOBAL_CONSTANTS, DuePaymentInvoicesService, InvoiceService, CardsService) {

    var vm = this;
    var popup = this;
    popup.cancel = cancel;
    vm.filter = {};
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.selectAllRows = false;
    vm.hideFlag = false;
    vm.checkAllRows = checkAllRows;
    vm.init = init;
    vm.changeForm = changeForm;
    vm.getDuePaymentSummary = getDuePaymentSummary;
    vm.getSelectedInvoices = getSelectedInvoices;
    vm.getSelectedInvoiceIds = getSelectedInvoiceIds;
    vm.getInitiatePayment = getInitiatePayment;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.paymentMethods = GLOBAL_CONSTANTS.PAYMENT_METHODS;
    vm.checkPaymentMethod = checkPaymentMethod;
    vm.externalPayments = externalPayments;
    vm.getSelectedPOs = getSelectedPOs;
    vm.getSelectedPOIds = getSelectedPOIds;
    vm.cancel = cancel;
    vm.existFilters = {};
    vm.getSelectedInvoiceIdsforExternal=getSelectedInvoiceIdsforExternal;
    vm.filter.paymentMethod = vm.paymentMethods[0].value;
    vm.sipRequest = sipRequest;
    vm.buyerSelectionChanged = buyerSelectionChanged;
    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.getSelectedPOsForSIPApproval = getSelectedPOsForSIPApproval;
    vm.types = GLOBAL_CONSTANTS.OCTOPUS_EZYPAYMENT_INVOICE_TYPES;
    vm.approvalStatuses = GLOBAL_CONSTANTS.SIP_EXTERNAL_APPROVAL_STATUSES;
    vm.filter.type = localStorage.getItem("type") ? localStorage.getItem("type") : 'INTERNAL';
    localStorage.removeItem("type");
    vm.confirmRemoveExternalInvoiceModal=confirmRemoveExternalInvoiceModal;
    vm.removeExternalInvoice=removeExternalInvoice;
    var selectedInvoiceIds="";
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.filter.buyerIdList=[];
    vm.requestApprove = requestApprove;
    vm.tokenCards;
    // 2FA changes
    vm.otp = undefined;
    vm.isOtpValid = true;
    vm.counterMilliSeconds = undefined;
    vm.counterSeconds = undefined;
    vm.counterMinutes = undefined;
    vm.validateOTP = validateOTP;
    vm.resendOTP = resendOTP;
    vm.dataValid;
    vm.popData;
    vm.fData = [];
    vm.ffData = [];
    vm.floadData = floadData;

    function init(){
      vm.fData = [];
      vm.ffData = [];
      vm.getInitiatePayment();
    }

    // timer for otp resend
    function counter() {
      vm.stopped = $timeout(function () {
        vm.counterMilliSeconds -= 1000;
        if (vm.counterMilliSeconds) {
          var seconds = vm.counterMilliSeconds / 1000;
          vm.counterMinutes = Math.floor(seconds / 60);
          if(vm.counterMinutes > 0) {
            vm.counterSeconds = seconds - (vm.counterMinutes * 60);
          }
          else {
            vm.counterSeconds = seconds;
          }
          counter();
        }
        else {
          vm.counterSeconds = 0;
          vm.counterMinutes = 0;
          stopCounter();
        }
      }, 1000);
    };

    function floadData() {
      vm.hideFlag = false;
      vm.fData = [];
      vm.ffData = [];
      var ids = [];
      var selectedPOs = vm.getSelectedPOs(vm.initiatePayment);
      selectedPOs.forEach(function (elem){
        ids.push(elem.brand.id);
      });
      ids = new Set(ids);
      ids.forEach(function (el){
        var rows = selectedPOs.filter(function (val){
          return val.brand.id == el;
        });
        var totAmt = 0;
        var buyerName = '';
        var brandName = '';
        rows.forEach(function (val) {
          buyerName = val.companyName;
          brandName = val.brand.name;
          if(val.withCreditNote) {
            totAmt = totAmt - val.creditNoteAmount;
          } else {
            totAmt = totAmt + val.invoiceAmount;
          }
        });
        vm.ffData.push({buyer:buyerName,brand:brandName,totAmt:totAmt});
      })
      vm.ffData = _.sortBy( vm.ffData, 'brand' );
    }
    function stopCounter(){
      vm.isOtpValid = false;
      $timeout.cancel(vm.stopped);
    }

    function showOtpPrompt() {
      // clear otp
      vm.otp = undefined;
      vm.isOtpValid = true;
      vm.counterSeconds = 0;
      vm.counterMinutes = 0;
      vm.counterMilliSeconds = GLOBAL_CONSTANTS.OTP_EXPIRY_MINUTES * 60 * 1000;
      counter();
      // show prompt
      $mdDialog.show({
        templateUrl: 'app/core/common-templates/otp-templates/otp-capture.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function validateOTP() {
      if (!vm.otp) {
        toastr.error('Invalid OTP!', 'Please check the OTP entered');
        return;
      }

      if (vm.otp && (vm.otp.length < 5 || vm.otp.length > 8)) {
        toastr.error('Invalid OTP!', 'Please check the OTP entered');
        return;
      }
      externalPayments(vm.otp);
    }

    function resendOTP() {
      externalPayments();
    }

    function checkAllRows() {
      vm.selectAllRows ? vm.selectAllRows = true : vm.selectAllRows = false;
      angular.forEach(vm.initiatePayment, function(invoice) {
        invoice.selected = vm.selectAllRows;
      });
    }

    function removeExternalInvoice(invoiceId){
          vm.cancel();
          DuePaymentInvoicesService.removeExternalInvoice(invoiceId).then(function(response) {
            NotificationService.success({
              title: 'External Invoice',
              message: 'alertMessage.DELETED_SUCCESSFULLY'
            });
            vm.getInitiatePayment();
          }, function(error) {
            NotificationService.error({
              title: 'External Invoice',
              error: error
            });
          });
        }

        function confirmRemoveExternalInvoiceModal(event, invoiceId) {
            var confirm = $mdDialog.confirm()
            .title($filter('translate')('alertMessage.REMOVE_INVOICE_CONFIRMATION'))
            .targetEvent(event)
            .ok('Delete')
            .cancel('Cancel');

            $mdDialog.show(confirm).then(function() {
              removeExternalInvoice(invoiceId);
            }, function() {});
          }

    function sortByStatus(a, b) {
      if (a.sipInvoiceApprovalStatus < b.sipInvoiceApprovalStatus) {
        return 1;
      }
      if (a.sipInvoiceApprovalStatus > b.sipInvoiceApprovalStatus) {
        return -1;
      }
      return 0;
    }

    function getInitiatePayment(){
      if(vm.filter.type=='INTERNAL'){
        vm.filter.invoiceApprovalRequestStatus = '';
      }
      if(vm.filter.type=="EXTERNALSIP" || vm.filter.type=="EXTERNAL_SIP_WITH_CREDIT_NOTE"){
      DuePaymentInvoicesService.getExternalInvoices({
          page: vm.query.page - 1,
          size: vm.query.limit,
          poNumber: !vm.filter.poNumber ? '': vm.filter.poNumber,
          invoiceNumber: !vm.filter.invoiceNumber ? '' : vm.filter.invoiceNumber,
          fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
          toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
          brandIds: !vm.filter.brandIds ? '' : String(vm.filter.brandIds),
          buyerIds:!vm.filter.buyerIdList?[]:vm.filter.buyerIdList,
          supplierId: !vm.filter.supplierIds ? '' : String(vm.filter.supplierIds),
          invoiceApprovalRequestStatus : !vm.filter.invoiceApprovalRequestStatus ? '': vm.filter.invoiceApprovalRequestStatus,
          paymentStatus: "'UNPAID','FAILED','VOID','CANCELLED'",
          kycStatus: 'SUCCESSFUL',
          paymentMethod: vm.filter.paymentMethod,
          type: !vm.filter.type ? '': vm.filter.type,
          sort: sort()
            }).then(function(response){
              if(Array.isArray(response.data) && response.data.length) {
                response.data.sort(sortByStatus);
              }
             vm.initiatePayment = response.data;
             vm.totalItems = response.headers('X-Total-Count');
            },function(error) {
              NotificationService.error({
                title: 'Fetching External Invoices',
                error: error
              });
            });
      }else{
        const queryParams ={
          page: vm.query.page - 1,
          size: vm.query.limit,
          sort:sort() // Pass as a string
        };
        var reqBody={
          query: !vm.filter.poNumber ? '': vm.filter.poNumber,
          invoiceNumber: !vm.filter.invoiceNumber ? '' : vm.filter.invoiceNumber,
          fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
          toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
          brandIds: !vm.filter.brandIds ? null : vm.filter.brandIds,
          buyerIds: (Array.isArray(vm.filter.buyerIdList) && vm.filter.buyerIdList.length > 0) ? vm.filter.buyerIdList : null,
          businessOutletIds: !vm.filter.businessOutletIds ? null : vm.filter.businessOutletIds,
          supplierId: null,
          paymentStatus: "'UNPAID','FAILED','VOID','CANCELLED'",
          kycStatus: 'SUCCESSFUL',
          module: 'supplierPayment',
          invoiceStatus: 'PROCESSED',
          ocrInvoiceStatus: '',
          ocrAcceptanceStatus: '',
          geoExported: '',
          paymentMethod: vm.filter.paymentMethod,
          hardcopyInvoiceNumber: !vm.filter.hardcopyInvoiceNumber ? '' : vm.filter.hardcopyInvoiceNumber,
          type: !vm.filter.type ? '': vm.filter.type,
          invoiceApprovalRequestStatus: !vm.filter.invoiceApprovalRequestStatus || vm.filter.invoiceApprovalRequestStatus === "" ? null : vm.filter.invoiceApprovalRequestStatus,
        };
      BuyerOCRService.getAllPurchaseOrders(queryParams,reqBody).then(function(response){
        vm.initiatePayment = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      },function(error){
        NotificationService.error({
          title: 'Fetching Invoices',
          error: error
        });
      });
      }
    }

    function requestApprove(invoice) {
      sendInvoiceIdsForApproval([invoice.id]);
    }

    function getInvoiceCounts(selectedPOs) {
      var invoiceCounts = {
        buyerApprovalNeededInvoiceCount: 0,
        pendingInvoiceCount: 0
      }
      selectedPOs.forEach(function (po) {
        if(po.sipBuyerApproval && (po.sipInvoiceApprovalStatus === 'NEW' || po.sipInvoiceApprovalStatus === 'REJECTED')) {
          ++invoiceCounts.buyerApprovalNeededInvoiceCount;
        }
        else if(po.sipBuyerApproval && po.sipInvoiceApprovalStatus === 'PENDING') {
          ++invoiceCounts.pendingInvoiceCount;
        }
      });
      return invoiceCounts;
    }

    function sendInvoiceIdsForApproval(invoiceIdList) {
      if (invoiceIdList.length < 0) {
        toastr.error('Please select invoices.', 'Approval Request');
        return;
      }
      EzypaymentInitiatePaymentService.sendForBuyerApproval(invoiceIdList)
        .then(function (response) {
          NotificationService.success({
            title: 'global.buttons.sipRequest',
            message: 'alertMessage.SIP_REQUEST_SENT'
          });
          vm.init();
        }, function (error) {
          NotificationService.error({
            title: 'global.buttons.sipRequest',
            error: error
          });
        });
    }

    function getDuePaymentSummary() {
      var remitFlag = 0;
      vm.dataValid = [];
      var selectedInvoices = vm.getSelectedInvoices(vm.initiatePayment);
      var selectedInvoiceIds = vm.filter.type == "EXTERNALSIP" || vm.filter.type == "EXTERNAL_SIP_WITH_CREDIT_NOTE" ? vm.getSelectedInvoiceIdsforExternal(selectedInvoices) : vm.getSelectedInvoiceIds(selectedInvoices);
      if(vm.filter.type == "EXTERNAL_SIP_WITH_CREDIT_NOTE"){
        EzypaymentInitiatePaymentService.getDuePaymentSummaryWithCredit({
          invoiceIdList: String(selectedInvoiceIds),
          paymentType: vm.filter.type
        }).then(function (response) {
          vm.dataValid = response.data;
          var validFlag = [];
          var brandIds = [];
          vm.dataValid.forEach(function (elem){
          brandIds.push(elem.brandId);
            if(!elem.remitanceAmountValid){
              validFlag.push(elem);
            }
          });
          CardsService.getAllCards({
            brandIds: brandIds,
            page: 0,
            size: paginationConstants.maxLimit
          }).then(function (response) {
            vm.tokenCards = response.data;
            var data = [];
            brandIds.forEach(function (elem){
                var dObj = {};
                dObj = vm.tokenCards.filter(function (row){
                        return (row.brandId === elem && row.list.length != 0);
                    })[0];
                if(dObj) {
                    data.push(dObj);
                }
            })
            var bName;
            if(data.length != brandIds.length){
                var bName = [];
                vm.tokenCards.forEach(function (el){
                    if(el.list.length == 0){
                        var name = vm.dataValid.filter(function(e){
                            return e.brandId === el.brandId;
                        })[0].brandName;
                        bName.push(name);
                    }
                });
                if(remitFlag == 0){
                    toastr.error("Card Not Found For "+ bName.toString(), 'VALIDATION FAILED');
                    remitFlag = remitFlag + 1;
                }
            } else {
              popup.popData = validFlag;
              if(validFlag.length > 0){
                if(remitFlag == 0){
                  toastr.error('Payment amount should be greater than 0', 'VALIDATION FAILED');
                // show prompt
                $mdDialog.show({
                  templateUrl: 'app/core/common-templates/initial-payment-popup.tmpl.html',
                  controller: function () {
                    return popup;
                  },
                  controllerAs: 'vm',
                  clickOutsideToClose: true
                });
                remitFlag = remitFlag + 1;
                }
              } else {
                // brandId
                proceedToDetailsPagee();
                function proceedToDetailsPagee() {
                  if (selectedInvoiceIds.length > 0) {
                    EzypaymentInitiatePaymentService.setSelectedInvoiceIds(selectedInvoiceIds);
                    $state.go('ezypayment.supplier.initiate-payment.details', { 'type': vm.filter.type, 'selectedInvoiceIds': selectedInvoiceIds });
                  } else {
                    toastr.error('Please select invoices to pay.', 'Fetching Invoice Details');
                  }
                }
              }
            }
        //   })
          }, function (error) {
            NotificationService.error({
              title: 'Fetching Token By Cards',
              error: error
            });
          });

        }, function (error) {
          NotificationService.error({
            title: 'Fetching External Due Payment Summary',
            error: error
          });
        });
      } else {
        proceedToDetailsPage();
        function proceedToDetailsPage() {
          if (selectedInvoiceIds.length > 0) {
            EzypaymentInitiatePaymentService.setSelectedInvoiceIds(selectedInvoiceIds);
            $state.go('ezypayment.supplier.initiate-payment.details', { 'type': vm.filter.type, 'selectedInvoiceIds': selectedInvoiceIds });
          } else {
            toastr.error('Please select invoices to pay.', 'Fetching Invoice Details');
          }
        }
      }
    }

    function getSelectedInvoices(invoices) {
      var selectedInvoices = _.filter(invoices, function(invoice) {
        return invoice.selected === true;
      });
      return selectedInvoices;
    }

    function getSelectedInvoiceIds(selectedInvoices) {
      var invoiceIdList = _.map(selectedInvoices, _.property('purchaseOrder.id'));
      return invoiceIdList;
    }
    function getSelectedInvoiceIdsforExternal(selectedInvoices) {
          var invoiceIdList = _.map(selectedInvoices, _.property('id'));
          return invoiceIdList;
        }

    //select all buyers mapped to the supplier
    function selectAll(dropdown){
      switch(dropdown){
        case 'BUYERS':
          vm.filter.buyerIdList = _.map(vm.buyers, _.property('id'));
        break;
      }
    }

    //deselect all buyers
    function deselectAll(dropdown){
      switch(dropdown){
        case 'BUYERS':
        vm.filter.buyerIdList = [];
      }
    }

    //Get all buyers mapped to the supplier
      function getAllSupplierMappedBuyers() {
        if (vm.buyers && !vm.filter.buyerSelectionChanged)
          return false;
        vm.filter.buyerSelectionChanged = false;
        var params = {
          page: 0,
          size: paginationConstants.maxLimit,
        };
        var supplierId = SupplierDataService.getSupplierProfile().supplier.id;
        BuyerSupplierMappingService.getAllSupplierMappedBuyers(supplierId, params).then(function (response) {
          vm.buyers = response.data;
          vm.filter.selectedBuyerIdList = [];
        }, function (error) {
        });
      }

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
     * [confirmAndPay Show the modal to enter the remarks]
     * @param {*} event
     */
    function confirmAndPay(event) {
      var selectedPOs = vm.getSelectedPOs(vm.initiatePayment);
      var selectedPOIds;
      // get external invoice ids
      if (vm.filter.type == "EXTERNALSIP" || vm.filter.type == "EXTERNAL_SIP_WITH_CREDIT_NOTE") {
        selectedPOIds = vm.getSelectedInvoiceIdsforExternal(selectedPOs);
      }
      else {
        selectedPOIds = vm.getSelectedInvoiceIds(selectedPOs);
      }
      if (selectedPOIds.length <= 0) {
        NotificationService.simpleErrorToast({
          title: 'global.fetchingInvoiceDetails',
          message: 'global.pleaseSelectInvoiceToPay'
        });
        return;
      }

      // if no approval needed, go for payment request
      $mdDialog.show({
        templateUrl: 'app/common-modules/ezypayment/ezypayment.payment.confirm-pay.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function changeForm(key) {
    }

    /**
     * [checkPaymentMethod Decides whether to goto details page or show popup]
     */
    function checkPaymentMethod() {
      vm.hideFlag = true;
      var selectedPOs = vm.getSelectedPOs(vm.initiatePayment);
      if(!Array.isArray(selectedPOs) || !selectedPOs.length) {
        toastr.error('Please select invoices to pay.', 'Fetching Invoice Details');
        return;
      }
      var proceed = true;
      var selectedPOSipStatus = [];
      if (vm.filter.type == "EXTERNAL") {
        // search the selected invoices for invoice type SIP_INVOICE
        selectedPOSipStatus = _.find(selectedPOs, ['purchaseOrder.invoice.type', 'SIP_INVOICE']);
        // when found one, check all invoices are approved
        if (selectedPOSipStatus) {
          _.map(selectedPOs, function (po) {
            if (po.invoiceApprovalRequestStatus !== 'APPROVED') {
              proceed = false;
            }
          });
        }
      }
      else if (vm.filter.type == "EXTERNALSIP" || vm.filter.type == "EXTERNAL_SIP_WITH_CREDIT_NOTE") {
        selectedPOs.forEach(function(selectedPo) {
          proceed = selectedPo.sipBuyerApproval && selectedPo.sipInvoiceApprovalStatus !== 'APPROVED' ? false : proceed
        });
      }
      if (!proceed) {
        toastr.error('Please select Approved invoices.', 'Approval Request');
        return;
      }
      vm.filter.paymentMethod === 'EZYPAY' ? vm.getDuePaymentSummary() : confirmAndPay();
    }

    /**
     * [externalPayments Pays external payment]
     */
    function externalPayments(otp) {
      var selectedPOs = vm.getSelectedPOs(vm.initiatePayment);
      var selectedPOIds = vm.getSelectedPOIds(selectedPOs);
      if(selectedPOIds.length > 0){
        vm.externalPaymentDetails ={
          purchaseOrderIdList :selectedPOIds ? selectedPOIds : [],
          remarks:vm.remarks,
          paymentMethod: vm.filter.paymentMethod
        }
        DuePaymentInvoicesService.externalConfirmPayment(vm.externalPaymentDetails, otp)
        .then(function(response){
          if (response.headers('authorization-otp') == 'OTP_VERIFICATION_REQUIRED') {
            showOtpPrompt();
          }
          else if(GLOBAL_CONSTANTS.OTP_EXCEPTIONS.includes(response.headers('authorization-otp'))) {
            toastr.error('Invalid OTP', 'AUTHENTICATION FAILED');
          }
          else {
            NotificationService.success({
              title: 'global.paymentForInvoices',
              message: 'global.successfullyMadePayments'
            });
            $mdDialog.hide();
            vm.cancel();
            vm.init();
          }
        },function(error) {
          NotificationService.error({
            title: 'global.paymentForInvoices',
            error: error
          });
          vm.cancel();
        });

      } else {
        NotificationService.simpleErrorToast({
          title: 'global.fetchingInvoiceDetails',
          message: 'global.pleaseSelectInvoiceToPay'
        });
      }
    }

    function buyerSelectionChanged() {
          vm.filter.buyerSelectionChanged = true;
          vm.filter.brandIdList = [];
          vm.filter.businessOutletIdList = [];
        }

    /**
     * [getSelectedPOs Filters selected POs]
     * @param {*} poItems
     */
    function getSelectedPOs(poItems) {
      var selectedPOs = _.filter(poItems, function(poItem) {
        return poItem.selected === true;
      });
      return selectedPOs;
    }

    /**
     * [getSelectedPOsForSIPApproval Filters selected POs]
     * @param {*} poItems
     */
    function getSelectedPOsForSIPApproval(poItems) {
      var selectedPOs = _.filter(poItems, function (po) {
      if (vm.filter.type == "EXTERNAL" && (po.selected && po.purchaseOrder.invoice.type == 'SIP_INVOICE')) {
        return po;
      }
      else if ((vm.filter.type == "EXTERNALSIP" || vm.filter.type == "EXTERNAL_SIP_WITH_CREDIT_NOTE") && (po.selected && po.sipBuyerApproval)) {
        return po;
      }
      });
      return selectedPOs;
    }

    /**
     * [getSelectedPOIds Filters selected PO ids]
     * @param {*} selectedPOs
     */
    function getSelectedPOIds(selectedPOs) {
      var purchaseOrderIdList = _.map(selectedPOs, _.property(vm.filter.type === 'EXTERNAL_SIP_WITH_CREDIT_NOTE' ? 'id' : 'purchaseOrder.id'));
      return purchaseOrderIdList;
    }

    function cancel() {
      $mdDialog.cancel();
    }

    function sipRequest(){
      var invoiceIdList = [];
      var selectedPOs = vm.getSelectedPOsForSIPApproval(vm.initiatePayment);
      if(!Array.isArray(selectedPOs) || !selectedPOs.length) {
        toastr.error('Please select valid invoices', 'Error');
        return;
      }
      if(vm.filter.type == "EXTERNAL") {
        invoiceIdList = InvoiceService.getInvoiceIds(selectedPOs);
        EzypaymentInitiatePaymentService.sipRequest(invoiceIdList)
        .then(function(response){
          NotificationService.success({
            title: 'global.buttons.sipRequest',
            message: 'alertMessage.SIP_REQUEST_SENT'
          });
          vm.init();
        }, function(error){
          NotificationService.error({
            title: 'global.buttons.sipRequest',
            error: error
          });
        });
      }
      else if(vm.filter.type == "EXTERNALSIP" || vm.filter.type == "EXTERNAL_SIP_WITH_CREDIT_NOTE") {
        var invoiceCounts = getInvoiceCounts(selectedPOs);
        if(invoiceCounts.buyerApprovalNeededInvoiceCount === selectedPOs.length) {
          invoiceIdList = getSelectedInvoiceIdsforExternal(selectedPOs);
          sendInvoiceIdsForApproval(invoiceIdList);
        }
        else if(invoiceCounts.pendingInvoiceCount === selectedPOs.length) {
          toastr.error("Invoice was already sent for approval", "Approval Request");
        }
        else {
          toastr.error("Please choose NEW or REJECTED invoices", "Approval Request");
        }
      }
    }
  }
})();
