/*
 * @Author: Jayalekshmi
 * @Project: Ezyprocure
 * @Filename: admin.amendments.controller.js
 * @Date: 2020-03-04 17:40:02
 * @Last Modified By : Jayalekshmi
 * @Last Modified time: 2020-03-05 15:01:33
 */

(function() {
    'use strict';

    angular.module('ezyprocureApp').controller('AdminAmendmentsController', AdminAmendmentsController);

    AdminAmendmentsController.$inject = [
        '$scope',
        'Auth',
        'UTCDateService',
        'AdminAmendmentsService',
        'NotificationService',
        '$mdDialog',
        'GLOBAL_CONSTANTS',
        'toastr',
        'BuyerItemService',
        '$q',
        '$filter','BuyerAdhocPOFormService','UtilService','UserAccessService','UomService','paginationConstants','ManageBuyerService'
    ];

    function AdminAmendmentsController(
        $scope,
        Auth,
        UTCDateService,
        AdminAmendmentsService,
        NotificationService,
        $mdDialog,
        GLOBAL_CONSTANTS,
        toastr,
        BuyerItemService,
        $q,
        $filter,BuyerAdhocPOFormService,UtilService,UserAccessService,UomService,paginationConstants,ManageBuyerService
    ) {
        var vm = this;

        vm.init = init;
        vm.runAmendments = runAmendments;
        vm.showAdminAmendmentsPasswordModal = showAdminAmendmentsPasswordModal;
        vm.cancel = cancel;
        vm.checkPassword = checkPassword;
        vm.sha256 = sha256;
        vm.currentDate = new Date();
        vm.configured_admin_amend_pass = GLOBAL_CONSTANTS.CONFIGURED_ADMIN_AMEND_PASS;
        vm.submitPOChangeDeliveryDate = submitPOChangeDeliveryDate;
        vm.poChangeDeliveryDate = {};
        vm.submitPOChangeItemQty = submitPOChangeItemQty;
        vm.poChangeItemQty = {};
        vm.submitPOChangeItemPrice = submitPOChangeItemPrice;
        vm.poChangeItemPrice = {};
        vm.submitPOChangeGRN = submitPOChangeGRN;
        vm.poChangeGRN = {};
        vm.submitPOChangeOCR = submitPOChangeOCR;
        vm.poChangeOCR = {};
        vm.submitPOChangeInvoice = submitPOChangeInvoice;
        vm.poChangeInvoice = {};
        vm.submitCancelPO = submitCancelPO;
        vm.cancelPO = {};
        vm.submitDeletePRPO = submitDeletePRPO;
        vm.deletePRPO = {};
        vm.submitPORemoveItem = submitPORemoveItem;
        vm.poRemoveItem = {};
        vm.submitPOAddItem = submitPOAddItem;
        vm.poAddItem = {};
        vm.submitPOChangeStatus = submitPOChangeStatus;
        vm.poChangeStatus = {};
        vm.submitPOChangeOrderDate = submitPOChangeOrderDate;
        vm.poChangeOrderDate = {};
        vm.checkByJP = "HELLO";
        vm.poChangeStatusSuccess = '';
        vm.poChangeStatusResult = '';
        vm.searchForItems = searchForItems;
        vm.itemSearchQuery = {};
        vm.poAddItemDisable = true;
        vm.poAddItemFeatureDisable = true;
        vm.selectedDates = selectedDates;
        vm.getAllItemList = {};
        var buyerIdList = [];
        var businessIdList = [];
        var supplierId = "";
        var getPoId = "";
        var poResponse=[];
        vm.pOremoveselectedDates = pOremoveselectedDates;
        vm.pOChangequantityselectedDates = pOChangequantityselectedDates;
        vm.pOchangePriceselectedDates = pOchangePriceselectedDates;
        vm.findBuyersBySupplier=findBuyersBySupplier;
        vm.getSuppliers=getSuppliers;
        vm.getUom=getUom;
        vm.updateUOM=updateUOM;
        vm.onItemCodeBlur=onItemCodeBlur;
        vm.restUomForm=restUomForm;
        var itemName = [];
        var itemsid = [];
        vm.getIdItem = getIdItem;
        var getIdItem = "";
        vm.updateUOMModal={};
        vm.masterItemUpdateUOMForm={};
        vm.showConfirm=showConfirm;
        vm.findBuyers=findBuyers;
        vm.getOutletCountByBuyerId=getOutletCountByBuyerId;
        vm.showConfirmDynamic=showConfirmDynamic;
        vm.resetmasterUpdateBuyerOutletCount=resetmasterUpdateBuyerOutletCount;
       
        function init() {
            
            // var isSetAmendPassword = sessionStorage.isSetAmendPassword ? JSON.parse(sessionStorage.isSetAmendPassword) : '';
            // if (isSetAmendPassword && isSetAmendPassword == 'true') {
            //     //vm.showAdminAmendmentsPasswordModal();
            //     // console.log('password set..........');
            // } 
           vm.showAdminAmendmentsPasswordModal();
            

        }
        
        
        function searchForItems(queryType, queryText) {
            var deferred = $q.defer();
            if (!queryText || JSON.stringify(vm.grnData) === JSON.stringify({})) {
                return;
            } else {
                vm.itemSearchQuery.queryField = queryType;
                vm.itemSearchQuery.queryText = queryText;
                vm.itemSearchQuery.businessIdList = businessIdList;
                vm.itemSearchQuery.buyerIdList = null;
                vm.itemSearchQuery.supplierId = supplierId;
                BuyerItemService.searchAllItems(vm.itemSearchQuery)
                    // SupplierItemService.searchAllItems(vm.itemSearchQuery)
                    .then(function(response) {
                        vm.poAddItemFeatureDisable = false;
                        for (var index = 0; index < response.data.length; index++) {
                            getPoId = response.data[index]['id'];
                        }

                        var filteredItems =UtilService.checkDuplicateItem(poResponse,response.data,1)
                        deferred.resolve($filter('orderBy')(filteredItems, 'itemName'));
                    }, function(error) {
                        deferred.reject(error);
                    });
            }
            return deferred.promise;
        }
        /**
         * [Run Amendments]
         */
        function runAmendments() {
            vm.poList = _.map(_.map(_.split(vm.poList, ','), _.trim), function(str) { return (parseInt(str)) })
            vm.amendments.date = UTCDateService.utcToDate(vm.amendments.date);
            vm.amendments.isBuyer = vm.amendments.isBuyer ? vm.amendments.isBuyer : false;
            AdminAmendmentsService.runAmendments(vm.amendments, JSON.stringify(vm.poList)).then(
                function(response) {
                    vm.supplierCalendars = 'Success';
                    NotificationService.success({
                        title: 'Admin Amendments',
                        message: 'alertMessage.COMPLETED_SUCCESSFULLY'
                    });
                },
                function(error) {
                    vm.supplierCalendars = 'Failed';
                    if (error) {
                        NotificationService.error({
                            title: 'Admin Amendments',
                            error: error
                        });
                    } else {
                        NotificationService.simpleErrorToast({
                            title: 'Admin Amendments',
                            message: 'alertMessage.error'
                        });
                    }
                }
            );
        }


        function showAdminAmendmentsPasswordModal() {
            // reset previous data if any
            vm.details = {};
            $mdDialog.show({
                templateUrl: 'app/admin-modules/amendmets/templates/admin.amendments.password-modal.tmpl.html',
                controller: function() {
                    return vm;
                },
                controllerAs: 'vm',
                escapeToClose: false,
                // targetEvent: event,
                clickOutsideToClose: false
            });
        }

        function cancel() {
            $mdDialog.cancel();
            Auth.roleBasedRoute();
        }

        function checkPassword(details) {
            if (vm.sha256(details.password) == vm.configured_admin_amend_pass) {
                $mdDialog.cancel();
                sessionStorage.isSetAmendPassword = JSON.stringify('true');
            } else {
                toastr.error('Invalid Password!', 'Authentication Failed');
            }
        }

        function sha256(ascii) {
            function rightRotate(value, amount) {
                return (value >>> amount) | (value << (32 - amount));
            };

            var mathPow = Math.pow;
            var maxWord = mathPow(2, 32);
            var lengthProperty = 'length'
            var i, j; // Used as a counter across the whole file
            var result = ''

            var words = [];
            var asciiBitLength = ascii[lengthProperty] * 8;

            //* caching results is optional - remove/add slash from front of this line to toggle
            // Initial hash value: first 32 bits of the fractional parts of the square roots of the first 8 primes
            // (we actually calculate the first 64, but extra values are just ignored)
            var hash = sha256.h = sha256.h || [];
            // Round constants: first 32 bits of the fractional parts of the cube roots of the first 64 primes
            var k = sha256.k = sha256.k || [];
            var primeCounter = k[lengthProperty];
            /*/
            var hash = [], k = [];
            var primeCounter = 0;
            //*/

            var isComposite = {};
            for (var candidate = 2; primeCounter < 64; candidate++) {
                if (!isComposite[candidate]) {
                    for (i = 0; i < 313; i += candidate) {
                        isComposite[i] = candidate;
                    }
                    hash[primeCounter] = (mathPow(candidate, .5) * maxWord) | 0;
                    k[primeCounter++] = (mathPow(candidate, 1 / 3) * maxWord) | 0;
                }
            }

            ascii += '\x80' // Append Ƈ' bit (plus zero padding)
            while (ascii[lengthProperty] % 64 - 56) ascii += '\x00' // More zero padding
            for (i = 0; i < ascii[lengthProperty]; i++) {
                j = ascii.charCodeAt(i);
                if (j >> 8) return; // ASCII check: only accept characters in range 0-255
                words[i >> 2] |= j << ((3 - i) % 4) * 8;
            }
            words[words[lengthProperty]] = ((asciiBitLength / maxWord) | 0);
            words[words[lengthProperty]] = (asciiBitLength)

            // process each chunk
            for (j = 0; j < words[lengthProperty];) {
                var w = words.slice(j, j += 16); // The message is expanded into 64 words as part of the iteration
                var oldHash = hash;
                // This is now the undefinedworking hash", often labelled as variables a...g
                // (we have to truncate as well, otherwise extra entries at the end accumulate
                hash = hash.slice(0, 8);

                for (i = 0; i < 64; i++) {
                    var i2 = i + j;
                    // Expand the message into 64 words
                    // Used below if
                    var w15 = w[i - 15],
                        w2 = w[i - 2];

                    // Iterate
                    var a = hash[0],
                        e = hash[4];
                    var temp1 = hash[7] +
                        (rightRotate(e, 6) ^ rightRotate(e, 11) ^ rightRotate(e, 25)) // S1
                        +
                        ((e & hash[5]) ^ ((~e) & hash[6])) // ch
                        +
                        k[i]
                        // Expand the message schedule if needed
                        +
                        (w[i] = (i < 16) ? w[i] : (
                            w[i - 16] +
                            (rightRotate(w15, 7) ^ rightRotate(w15, 18) ^ (w15 >>> 3)) // s0
                            +
                            w[i - 7] +
                            (rightRotate(w2, 17) ^ rightRotate(w2, 19) ^ (w2 >>> 10)) // s1
                        ) | 0);
                    // This is only used once, so *could* be moved below, but it only saves 4 bytes and makes things unreadble
                    var temp2 = (rightRotate(a, 2) ^ rightRotate(a, 13) ^ rightRotate(a, 22)) // S0
                        +
                        ((a & hash[1]) ^ (a & hash[2]) ^ (hash[1] & hash[2])); // maj

                    hash = [(temp1 + temp2) | 0].concat(hash); // We don't bother trimming off the extra ones, they're harmless as long as we're truncating when we do the slice()
                    hash[4] = (hash[4] + temp1) | 0;
                }

                for (i = 0; i < 8; i++) {
                    hash[i] = (hash[i] + oldHash[i]) | 0;
                }
            }

            for (i = 0; i < 8; i++) {
                for (j = 3; j + 1; j--) {
                    var b = (hash[i] >> (j * 8)) & 255;
                    result += ((b < 16) ? 0 : '') + b.toString(16);
                }
            }
            return result;
        };

        function submitPOChangeDeliveryDate(poChangeDeliveryDate) {
            AdminAmendmentsService.changeDeliveryDate(poChangeDeliveryDate).then(
                function(response) {
                    if (response.message == "success") {
                        vm.poChangeDeliveryDateResult = response.message;
                        vm.poChangeDeliveryDateSuccess = 'true';
                    } else {
                        vm.poChangeDeliveryDateSuccess = 'False';
                        vm.poChangeDeliveryDateResult = response.message;
                    }

                },
                function(error) {
                    vm.poChangeDeliveryDateSuccess = 'False';
                    vm.poChangeDeliveryDateResult = error.statusText;
                    if (error) {
                        NotificationService.error({
                            title: 'Admin Amendments',
                            error: error
                        });
                    } else {
                        NotificationService.simpleErrorToast({
                            title: 'Admin Amendments',
                            message: 'alertMessage.error'
                        });
                    }
                }
            );
        }

        function submitPOChangeItemQty(poChangeItemQty) {
            AdminAmendmentsService.changeItemQty(poChangeItemQty).then(
                function(response) {
                    if (response.message == "success") {
                        vm.poChangeItemQtySuccess = 'true';
                        vm.poChangeItemQtyResult = response.message;
                    }else{
                        vm.poChangeItemQtySuccess = 'False';
                        vm.poChangeItemQtyResult = response.message;
                    }
                },
                function(error) {
                    vm.poChangeItemQtySuccess = 'False';
                    vm.poChangeItemQtyResult = error.statusText;
                    if (error) {
                        NotificationService.error({
                            title: 'Admin Amendments',
                            error: error
                        });
                    } else {
                        NotificationService.simpleErrorToast({
                            title: 'Admin Amendments',
                            message: 'alertMessage.error'
                        });
                    }
                }
            );
        }

        function submitPOChangeItemPrice(poChangeItemPrice) {
            AdminAmendmentsService.changePoItemPrice(poChangeItemPrice).then(
                function(response) {
                    if (response.message == "success") {
                        vm.poChangeItemPriceSuccess = 'true';
                        vm.poChangeItemPriceResult = response.message;
                    }else{
                        vm.poChangeItemPriceSuccess = 'False';
                        vm.poChangeItemPriceResult = response.message;
                    }
                   
                },
                function(error) {
                    vm.poChangeItemPriceSuccess = 'False';
                    vm.poChangeItemPriceResult = error.statusText;
                    if (error) {
                        NotificationService.error({
                            title: 'Admin Amendments',
                            error: error
                        });
                    } else {
                        NotificationService.simpleErrorToast({
                            title: 'Admin Amendments',
                            message: 'alertMessage.error'
                        });
                    }
                }
            );
        }

        function submitPOChangeGRN(poChangeGRN) {
            AdminAmendmentsService.changeGrnStatusToPending(poChangeGRN).then(
                function(response) {
                    if (response.message == "success") {
                        vm.poChangeGRNSuccess = 'true';
                        vm.poChangeGRNResult = response.message;
                    }else{
                        vm.poChangeGRNSuccess = 'False';
                        vm.poChangeGRNResult = response.message;
                    }
                    
                },
                function(error) {
                    vm.poChangeGRNSuccess = 'False';
                    vm.poChangeGRNResult = error.statusText;
                    if (error) {
                        NotificationService.error({
                            title: 'Admin Amendments',
                            error: error
                        });
                    } else {
                        NotificationService.simpleErrorToast({
                            title: 'Admin Amendments',
                            message: 'alertMessage.error'
                        });
                    }
                }
            );
        }

        function submitPOChangeOCR(poChangeOCR) {
            AdminAmendmentsService.changePoOcrAcceptanceStatusToPending(poChangeOCR).then(
                function(response) {
                    if (response.message == 'success') {
                        vm.poChangeOCRResult = response.message;
                        vm.poChangeOCRSuccess = 'true';
                    } else {
                        vm.poChangeOCRSuccess = 'false';
                        vm.poChangeOCRResult = response.message;
                    }
                },
                function(error) {
                    vm.poChangeOCRSuccess = 'False';
                    vm.poChangeOCRResult = error.statusText;
                    if (error) {
                        NotificationService.error({
                            title: 'Admin Amendments',
                            error: error
                        });
                    } else {
                        NotificationService.simpleErrorToast({
                            title: 'Admin Amendments',
                            message: 'alertMessage.error'
                        });
                    }
                }
            );
        }

        function submitPOChangeInvoice(poChangeInvoice) {
            AdminAmendmentsService.changeInvoiceStatusToPending(poChangeInvoice).then(
                function(response) {
                    if (response.message == 'success') {
                        vm.poChangeInvoiceResult = response.message;
                        vm.poChangeInvoiceSuccess = 'true';
                    } else {
                        vm.poChangeInvoiceSuccess = 'false';
                        vm.poChangeInvoiceResult = response.message;
                    }
                },
                function(error) {
                    vm.poChangeInvoiceSuccess = 'False';
                    vm.poChangeInvoiceResult = error.statusText;
                    if (error) {
                        NotificationService.error({
                            title: 'Admin Amendments',
                            error: error
                        });
                    } else {
                        NotificationService.simpleErrorToast({
                            title: 'Admin Amendments',
                            message: 'alertMessage.error'
                        });
                    }
                }
            );
        }

        function submitCancelPO(cancelPO) {
            AdminAmendmentsService.cancelPo(cancelPO).then(
                function(response) {
                    if (response.message == "success") {
                        vm.cancelPOSuccess = 'true';
                        vm.cancelPOResult = response.message;
                    }else{
                        vm.cancelPOSuccess = 'False';
                        vm.cancelPOResult = response.message;
                    }
                },
                function(error) {
                    vm.cancelPOSuccess = 'False';
                    vm.cancelPOResult = error.statusText;
                    if (error) {
                        NotificationService.error({
                            title: 'Admin Amendments',
                            error: error
                        });
                    } else {
                        NotificationService.simpleErrorToast({
                            title: 'Admin Amendments',
                            message: 'alertMessage.error'
                        });
                    }
                }
            );
        }

        function submitDeletePRPO(deletePRPO) {
            AdminAmendmentsService.deletePrAndPo(deletePRPO).then(
                function(response) {
                    if (response.message == 'success') {
                        vm.deletePRPOResult = response.message;
                        vm.deletePRPOSuccess = 'true';
                    } else {
                        vm.deletePRPOSuccess = 'false';
                        vm.deletePRPOResult = response.message;
                    }

                },
                function(error) {
                    vm.deletePRPOSuccess = 'False';
                    vm.deletePRPOResult = error.statusText;
                    if (error) {
                        NotificationService.error({
                            title: 'Admin Amendments',
                            error: error
                        });
                    } else {
                        NotificationService.simpleErrorToast({
                            title: 'Admin Amendments',
                            message: 'alertMessage.error'
                        });
                    }
                }
            );
        }

        function submitPORemoveItem(poRemoveItem) {
            AdminAmendmentsService.removePoItem(poRemoveItem).then(
                function(response) {
                    if (response.message == 'success') {
                        vm.poRemoveItemSuccess = 'true';
                        vm.poRemoveItemResult = response.message;
                    }else{
                        vm.poRemoveItemSuccess = 'False';
                        vm.poRemoveItemResult = response.message;
                    }
                },
                function(error) {
                    vm.poRemoveItemSuccess = 'False';
                    vm.poRemoveItemResult = error.statusText;
                    if (error) {
                        NotificationService.error({
                            title: 'Admin Amendments',
                            error: error
                        });
                    } else {
                        NotificationService.simpleErrorToast({
                            title: 'Admin Amendments',
                            message: 'alertMessage.error'
                        });
                    }
                }
            );
        }
        function checkAccessByUserId(userID)
        {
            vm.accessForGlModule=false;
            UserAccessService.getUserAccess(userID).then(function(response) {
                var selectedUserAccess = response;

                if(selectedUserAccess.accessForGlModule){
                    vm.accessForGlModule=true;
                }
            }, function(error) {
                vm.errorGlcode= UtilService.addItemOnce(vm.errorGlcode,itemGroupId);
                notify(error);
          });
        }
        function getIdItem(id) {
            getIdItem = id.id;
            vm.accessForGlModule? checkGlCode(id.itemGroup):'';
        }
        vm.errorGlcode=[];
 function checkGlCode(itemGroupId)
    {
        BuyerAdhocPOFormService.getGlCodeMaping(vm.purchaseOrderTypeId.id,itemGroupId.id,buyerIdList[0]).then(function(response) {
            UtilService.removeItemOnce(vm.errorGlcode,itemGroupId);
        }, function(error) {
            toastr.error('Purchase order type '+vm.purchaseOrderTypeId.name+' is not mapped with item group '+itemGroupId.name+'', 'GL Mapping Listing', NotificationService.getToastrOptions());
       
      });
    }

    function notify(error){
        NotificationService.error({
          title: 'global.createRecoveryExternalPOForm',
          error: error
        });
      }
        function submitPOAddItem(poAddItem) {
           
            var purchaseOrderTypeId=vm.purchaseOrderTypeId?vm.purchaseOrderTypeId.id:'';
            var getItemIdData = getIdItem;
            var poAddItemDet = {
                    existingDeliveryDate: poAddItem['existingDeliveryDate'],
                    price: poAddItem['price'],
                    purchaseOrderNumber: poAddItem['purchaseOrderNumber'],
                    quantity: poAddItem['quantity'],
                    weight: poAddItem['weight'],
                    purchaseOrderTypeId:purchaseOrderTypeId,
                    itemId: getItemIdData
                }
                if(vm.errorGlcode.length>0)
                {
                  NotificationService.error({
                    title: 'global.createRecoveryExternalPOForm',
                    error: vm.gberror
                  });
                  vm.GlMappingError=true;
                  return;
                }
            AdminAmendmentsService.poAddItem(poAddItemDet).then(
                function(response) {
                    vm.poAddItemSuccess = 'true';
                    vm.poAddItemResult = "Success";
                },
                function(error) {
                    vm.poAddItemSuccess = 'False';
                    vm.poAddItemResult = error.statusText;
                    if (error) {
                        NotificationService.error({
                            title: 'Admin Amendments',
                            error: error
                        });
                    } else {
                        NotificationService.simpleErrorToast({
                            title: 'Admin Amendments',
                            message: 'alertMessage.error'
                        });
                    }
                }
            );
        }

        function selectedDates() {
            vm.getAllItemList.purchaseOrderNumber = vm.poAddItem.purchaseOrderNumber;
            vm.getAllItemList.deliveryDate = vm.poAddItem.existingDeliveryDate;
            var param = {
                    purchaseOrderNumber: vm.poAddItem.purchaseOrderNumber,
                    // purchaseOrderNumber: vm.poAddItem.purchaseOrderNumber,
                    deliveryDate: vm.poAddItem.existingDeliveryDate,
                    // deliveryDate: (date.setDate(date.getDate() + 1)).toISOString()
                }
            if (vm.poAddItem.purchaseOrderNumber != null && vm.poAddItem.existingDeliveryDate != null) {
                AdminAmendmentsService.getAllItemList(param).then(
                    function(response) {
                          vm.poAddItemDisable = false;
                           poResponse=response;
                        for (var index = 0; index < response.length; index++) {
                            businessIdList.push(response[index]['processOrder']['buyerBusiness']['id']);
                            buyerIdList.push(response[index]['processOrder']['buyerBusiness']['buyer']['id']);
                            // supplierId.push(response[index]['processOrder']['supplierId'])
                        }
                        supplierId = response[0]['processOrder']['items']['supplier']['id'];
                       try {
                        vm.purchaseOrderTypeId = response[0]['processOrder']['glCodeMappings']['purchaseOrderTypes'];
                        } catch (error) {}
                        var userId = response[0]['processOrder']['buyerUser']['user']['id'];
                        checkAccessByUserId(userId) 
                    },
                    function(error) {
                        console.log('submitPOAddItem error: ', error);

                    }
                )

            }
        }

        // 
        function pOChangequantityselectedDates() {
            vm.getAllItemList.purchaseOrderNumber = vm.poChangeItemQty.purchaseOrderNumber;
            vm.getAllItemList.deliveryDate = vm.poChangeItemQty.existingDeliveryDate;
            var param = {
                    purchaseOrderNumber: vm.poChangeItemQty.purchaseOrderNumber,
                    // purchaseOrderNumber: vm.poChangeItemQty.purchaseOrderNumber,
                    deliveryDate: UTCDateService.utcFromDate(vm.poChangeItemQty.existingDeliveryDate),
                    // deliveryDate: Date(vm.poAddItem.existingDeliveryDate).toString(),

                    // deliveryDate: (date.setDate(date.getDate() + 1)).toISOString()
                }
                // if (vm.poChangeItemQty.purchaseOrderNumber != null && vm.poAddItem.existingDeliveryDate != null) {
            AdminAmendmentsService.getAllItemList(param).then(
                function(response) {
                    vm.allItemsName = null;
                    vm.allItemsName = response;

                    // console.log('pOChangequantityselectedDates: ', response);
                    vm.poAddItemDisable = false;
                    for (var index = 0; index < response.length; index++) {
                        businessIdList.push(response[index]['processOrder']['buyerBusiness']['id']);
                        buyerIdList.push(response[index]['processOrder']['buyerBusiness']['buyer']['id']);
                        // supplierId.push(response[index]['processOrder']['supplierId'])
                        itemsid.push(response[index]['processOrder']['items']['id']);
                        itemName.push(response[index]['processOrder']['items']['itemName'])
                    }

                    vm.pOChangequantityselectedDatesArr.ItemName = vm.allItemsName[0].ItemName;
                    supplierId = response[0]['processOrder']['items']['supplier']['id'];

                },
                function(error) {
                    console.log('submitPOAddItem error: ', error);

                }
            )

            // }
        }

        function pOchangePriceselectedDates() {
            vm.getAllItemList.purchaseOrderNumber = vm.poChangeItemPrice.purchaseOrderNumber;
            vm.getAllItemList.deliveryDate = vm.poChangeItemPrice.existingDeliveryDate;
            var param = {
                purchaseOrderNumber: vm.poChangeItemPrice.purchaseOrderNumber,
                // purchaseOrderNumber: vm.poChangeItemPrice.purchaseOrderNumber,
                deliveryDate: UTCDateService.utcFromDate(vm.poChangeItemPrice.existingDeliveryDate),
                // deliveryDate: (date.setDate(date.getDate() + 1)).toISOString()
            }
            // if (vm.poAddItem.purchaseOrderNumber != null && vm.poAddItem.existingDeliveryDate != null) {
            AdminAmendmentsService.getAllItemList(param).then(
                function(response) {
                    vm.poChangeallItemsName = null;
                    vm.poChangeallItemsName = response;
                    for (var index = 0; index < response.length; index++) {
                        businessIdList.push(response[index]['processOrder']['buyerBusiness']['id']);
                        buyerIdList.push(response[index]['processOrder']['buyerBusiness']['buyer']['id']);
                        // supplierId.push(response[index]['processOrder']['supplierId'])
                    }
                    supplierId = response[0]['processOrder']['items']['supplier']['id'];
                },
                function(error) {
                    console.log('submitPOAddItem error: ', error);

                }
            )

            // }
        }

        function pOremoveselectedDates() {
            vm.getAllItemList.purchaseOrderNumber = vm.poRemoveItem.purchaseOrderNumber;
            vm.getAllItemList.deliveryDate = vm.poRemoveItem.existingDeliveryDate;
            var param = {
                purchaseOrderNumber: vm.poRemoveItem.purchaseOrderNumber,
                // purchaseOrderNumber: vm.poRemoveItem.purchaseOrderNumber,
                deliveryDate: UTCDateService.utcFromDate(vm.poRemoveItem.existingDeliveryDate),
                // deliveryDate: (date.setDate(date.getDate() + 1)).toISOString()
            }
            
            if (vm.poRemoveItem.purchaseOrderNumber != null && vm.poRemoveItem.existingDeliveryDate != null) {
                AdminAmendmentsService.getAllItemList(param).then(
                    function(response) {
                        vm.poRemoveallItemsName = null;
                        vm.poRemoveallItemsName = response;
                        vm.poAddItemDisable = false;
                        for (var index = 0; index < response.length; index++) {
                            businessIdList.push(response[index]['processOrder']['buyerBusiness']['id']);
                            buyerIdList.push(response[index]['processOrder']['buyerBusiness']['buyer']['id']);
                            // supplierId.push(response[index]['processOrder']['supplierId'])
                        }
                        supplierId = response[0]['processOrder']['items']['supplier']['id'];
                    },
                    function(error) {
                        console.log('submitPOAddItem error: ', error);

                    }
                )

            }
        }

        function submitPOChangeStatus(poChangeStatus) {
            AdminAmendmentsService.changePOStatus(poChangeStatus).then(
                function(response) {
                    if (response === 'success') {
                        vm.poChangeStatusSuccess = 'true';
                        vm.poChangeStatusResult = response;
                    } else {
                        vm.poChangeStatusSuccess = 'False';
                        // vm.poChangeStatusResult = response.message;
                        vm.poChangeStatusResult = response;
                    }
                },
                function(error) {
                    console.log('submitPOChangeStatus error: ', error);
                    vm.poChangeStatusSuccess = 'False';
                    vm.poChangeStatusResult = error.statusText;
                    if (error) {
                        NotificationService.error({
                            title: 'Admin Amendments',
                            error: error
                        });
                    } else {
                        NotificationService.simpleErrorToast({
                            title: 'Admin Amendments',
                            message: 'alertMessage.error'
                        });
                    }
                }
            );
        }

        function submitPOChangeOrderDate(poChangeOrderDate) {
            console.log('poChangeOrderDate: ', poChangeOrderDate);
            AdminAmendmentsService.changePoOrderDate(poChangeOrderDate).then(
                function(response) {
                    if (response.message == 'success') {
                        vm.poChangeOrderDateSuccess = 'true';
                        vm.poChangeOrderDateResult = response.message;
                    } else {
                        vm.poChangeOrderDateSuccess = 'False';
                        vm.poChangeOrderDateResult = response.message;
                    }

                },
                function(error) {
                    
                    vm.poChangeOrderDateSuccess = 'False';
                    vm.poChangeOrderDateResult = error.statusText;
                    if (error) {
                        NotificationService.error({
                            title: 'Admin Amendments',
                            error: error
                        });
                    } else {
                        NotificationService.simpleErrorToast({
                            title: 'Admin Amendments',
                            message: 'alertMessage.error'
                        });
                    }
                }
            );
        }

        function findBuyersBySupplier(supplierId) {
            // Check if we need to fetch buyers based on supplierId
            var shouldFetchBuyers = vm.buyersList == null || vm.currentSupplierId !== supplierId;
        
            if (shouldFetchBuyers) {
                AdminAmendmentsService.getBuyersBySupplier(supplierId).then(
                    function(response) {
                        vm.buyersList = response;
                        vm.currentSupplierId = supplierId; // Update the stored supplier ID
                    },
                    function(error) {
                        console.log('getBuyers error: ', error);
                    }
                );
            }
        }
        
       function getSuppliers()
       {
        if(vm.supplierList==null)
            {  AdminAmendmentsService.getSuppliers().then(
                function(response) {
                     vm.supplierList=response;
                },
                function(error) {
                    console.log('getSuppliers error: ', error);
    
                }
            )

            }
      
       }

       function getUom()
       {
        if(vm.uomList==null)
            {  UomService.getAllUoms({
                page: 0,
                size: paginationConstants.maxLimit,
                sort: "",
                query: ""
              }).then(
                function(response) {
                    vm.uomList=response.data;
                },
                function(error) {
                    console.log('getSuppliers error: ', error);
    
                }
            )

            }
      
       }

       function onItemCodeBlur() {
        const form = vm.masterItemUpdateUOMForm;
    
        // Check if supplierId, itemCode, and buyerId are present in the form
        if (form.supplierId && form.itemCode && form.buyerId) {
            AdminAmendmentsService.getOldUomBySupplierIdAndBuyerIdAndItemCode(form.supplierId, form.buyerId, form.itemCode)
                .then(function(data) {
                    var uomList=data.uomList;
                    // Check if data is a non-empty array
                    if (Array.isArray(uomList) && uomList.length > 0) {
                        if (uomList.length > 1) {
                            console.log("Items are more than one,");
                            toastr.warning('Item has some errors!', 'Items are more than one.');
                            form.oldUom = null;
                            form.itemId = null;
                            return;
                        }
                        var firstItem = uomList[0];
                        form.oldUom = firstItem.oldUom;
                        form.itemId = firstItem.itemId;
                        
                        // Check if there are multiple items in the data list
                      
                    } else {
                        toastr.warning('Item has some errors!', 'Data is mismatched. Please enter correct data.');
                    }
                })
                .catch(function(error) {
                    console.error('Error fetching old UOM:', error);
                });
        }
    }
    
   
    

       function updateUOM() {
        var data={itemCode:vm.masterItemUpdateUOMForm.itemCode,supplierId:vm.masterItemUpdateUOMForm.supplierId,uomId:vm.masterItemUpdateUOMForm.uomId,itemId:vm.masterItemUpdateUOMForm.itemId}
        AdminAmendmentsService.updateUOMByItemCode(data).then(
            function(response) {
                if (response.message == 'success') {
                    vm.updateUOMDateSuccess = 'true';
                    vm.updateUOMDateResult = response.message;
                   // vm.restUomForm();
                } else {
                    vm.updateUOMDateSuccess = 'False';
                    vm.updateUOMDateResult = response.message;
                  //  vm.restUomForm();
                }

            },
            function(error) {
                
                vm.updateUOMDateSuccess = 'False';
                vm.updateUOMDateResult = error.statusText;
                if (error) {
                    NotificationService.error({
                        title: 'Admin Amendments',
                        error: error
                    });
                } else {
                    NotificationService.simpleErrorToast({
                        title: 'Admin Amendments',
                        message: 'alertMessage.error'
                    });
                }
            }
        );
    }
    function restUomForm()
    {
        vm.masterItemUpdateUOMForm={};
    }

    function showConfirm(ev) {
        var confirm = $mdDialog.confirm()
        .title('Are you sure you want to update Item Uom  ? ')
        .textContent('This action will update the item UOM.')
        .targetEvent(ev)
        .ok('Update')
        .cancel('Cancel');
  
        $mdDialog.show(confirm).then(function() {
            updateUOM();
        }, function() {});
      }


      /**
 * Fetches a list of buyers with minimal fields getAllAdminBuyersWithMinimalFields.
 * Utilizes pagination constants and default parameters for efficient API querying.
 * Displays an error notification if the request fails.
 */
      function findBuyers() {
        if(vm.buyersListForOutlet)
            return;
        var urlParams=  {
            page: 0,
            size: paginationConstants.maxLimit,
            search: '',
            sort: '',
            isAdminDashboard:false,
            minimalFields:true
        }
        ManageBuyerService.getAllAdminBuyersWithUserDetails(urlParams).then(function(response) {
            vm.buyersListForOutlet = response.data;
        },function(error) 
        {
            NotificationService.error({title: 'Buyer Listing',error: error});
        }
    );
    }

/**
 * Fetches the outlet count for a specific buyer ID and handles success or error responses.
 *
 * This function invokes the `AdminAmendmentsService.getOutletCountByBuyerId` method
 * to retrieve the outlet count for a given buyer ID. It processes the response and handles errors.
 *
 * @param {number} buyerId - The ID of the buyer for which the outlet count is required.
 */
function getOutletCountByBuyerId(buyerId,msg) {
    if (buyerId) {
        AdminAmendmentsService.getOutletCountByBuyerId(buyerId)
            .then(function(response) {
                if (response.outletCount > 0) {
                    // Handle the success case where outlet count is greater than 0
                    vm.masterUpdateBuyerOutletCount.existingOutletCount= response.outletCount;
                    vm.masterUpdateBuyerOutletCount.availableCount= response.availableCount;
                    vm.masterUpdateBuyerOutletCount.newOutletCount=''; 
                    // if (vm.masterUpdateBuyerOutletCount.availableCount < vm.masterUpdateBuyerOutletCount.existingOutletCount) {
                    //     // Display toaster notification and restrict increasing count
                       
                    //     if(!msg){toastr.warning("Active outlet count is less than the Existing counts. Cannot increase count.");}
                    // }
                    
                } else if (response.outletCount === 0) {
                    vm.masterUpdateBuyerOutletCount.existingOutletCount= response.outletCount;
                    vm.masterUpdateBuyerOutletCount.availableCount= response.availableCount;
               } else if (response.outletCount === -1) {
                    vm.masterUpdateBuyerOutletCount={}
                    // Handle the case where the back-end returned an error (-1)
                    console.error("An error occurred while retrieving the outlet count.");
                }
            })
            .catch(function(error) {
                // Handle any errors from the API request
                console.error("API call failed:", error);
            });
    } else {
        console.warn("Buyer ID is required to fetch outlet count.");
    }
}

/**
 * Sets a new outlet count for a specific buyer ID and handles success or error responses.
 *
 * This function invokes the `AdminAmendmentsService.setOutletCountByBuyerId` method
 * to update the outlet count for a given buyer ID. It processes the response and handles errors.
 *
 * @param {number} buyerId - The ID of the buyer for which the outlet count is to be updated.
 * @param {number} newOutletCount - The new outlet count value to be set.
 */
function setOutletCountByBuyerId() {
   var buyerId=vm.masterUpdateBuyerOutletCount.buyerId; var newOutletCount=vm.masterUpdateBuyerOutletCount.newOutletCount+vm.masterUpdateBuyerOutletCount.existingOutletCount;
    if (buyerId && newOutletCount >= 0) {  // Check if both buyerId and newOutletCount are provided
        AdminAmendmentsService.setOutletCountByBuyerId(buyerId, newOutletCount)
            .then(function(response) {
             if (response.outletCount >= 0) {
                  vm.masterUpdateBuyerOutletCount.result=response.outletCount;
                    vm.success=response.message;
                    vm.result=response.outletCount;
                    getOutletCountByBuyerId(buyerId,true);
                } else if (response.outletCount === -1) {
                    // Handle the case where the back-end returned an error (-1)
                    console.error("An error occurred while updating the outlet count.");
                }
            })
            .catch(function(error) {
                // Handle any errors from the API request
                console.error("API call failed:", error);
            });
    } else {
        console.warn("Both buyer ID and a valid outlet count are required to update outlet count.");
    }
}

/**
 * Shows a dynamic confirmation dialog based on the action type.
 *
 * This function displays a confirmation dialog with a title, message, and action 
 * based on the provided `type` parameter. It then calls the corresponding method 
 * based on the type of action (e.g., updating UOM, deleting an item, etc.).
 *
 * @param {Object} ev - The event object for the dialog.
 * @param {string} type - The action type that determines the dialog title and method to invoke.
 */
function showConfirmDynamic(ev, type) {
    // Define action details for different types in a single object
    const actionDetails = {
        updateOutletCount: {
            title: 'Are you sure you want to update the Outlet Count?',
            text: 'This action will update the Outlet Count.',
            method: setOutletCountByBuyerId
        } 
    };
     // Set default action if no valid type is found
    const action = actionDetails[type] || {
        title: 'Are you sure?',
        text: 'Please confirm your action.',
        method: null
    };

    // Create and show the confirmation dialog
    const confirm = $mdDialog.confirm()
        .title(action.title)
        .textContent(action.text)
        .targetEvent(ev)
        .ok('Yes')
        .cancel('No');

    $mdDialog.show(confirm).then(function() {
        // Execute the associated method if found
        if (action.method) {
            action.method();
        }
    }, function() {
        // Handle cancel or no action here if necessary
    });
}
function resetmasterUpdateBuyerOutletCount()
{
    vm.masterUpdateBuyerOutletCount=null;
    vm.masterUpdateBuyerOutletCount.result='';
    vm.masterUpdateBuyerOutletCount.success='';
}



    }
})();