/**
 * @Author: Ajay
 * @Date:   12-Jun-2017 17:53 +05:30
 * @Project: Ezyprocure
 * @Filename: pagination.constants.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Jun-2017 18:55 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .constant('paginationConstants', {
    'consolidatedItemsPerPage': 100,
    'itemsPerPage': 20,
    'maxLimit': 10000000,
    'limitOptions':[10, 20, 50
      , {
        label: 'All',
        value: function() {
          return 10000000;
        }
      }
    ],
    'limitOptionsWithAll':[10, 20, 50 
      , {
      label: 'All',
      value: function() {
        return 10000000;
      }
    }
   ],
   'limitOptionsWithoutAll':[10, 20, 50],
   'limitOptionsUpTo200':[ 20, 50,100,200],
   'limitOptionsUpTo100':[ 10,20,50,100]   
  });
})();
